import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminUsersTranslations: ITranslationMap = {
    adminUsersHeaderUsers: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'header', 'headline'],
        translations: {
            'de-DE': 'Benutzer',
            'en-GB': 'Users',
        },
    },
    adminUsersDescriptionUsers: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'description'],
        translations: {
            'de-DE': 'Hier können Sie Benutzer erstellen und bearbeiten.',
            'en-GB': 'Here you can create and edit users.',
        },
    },
    adminUsersTableName: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'table'],
        translations: {
            'de-DE': 'Name',
            'en-GB': 'Name',
        },
    },
    adminUsersButtonCreate: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'button'],
        translations: {
            'de-DE': 'Anlegen',
            'en-GB': 'Create',
        },
    },
    adminUsersPlaceholderNewUser: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'placeholder'],
        translations: {
            'de-DE': 'Neuer Benutzername',
            'en-GB': 'New user name',
        },
    },
    adminUsersSearchPlaceholder: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'placeholder'],
        translations: {
            'de-DE': 'Was suchen Sie',
            'en-GB': 'What are you looking for?',
        },
    },
    adminUsersToggleLabelArchivedUsers: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'toggle'],
        translations: {
            'de-DE': 'Archivierte Benutzer',
            'en-GB': 'Archived users',
        },
    },
    adminUsersToggleLabelSmallArchived: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'toggle'],
        translations: {
            'de-DE': 'Archivierte',
            'en-GB': 'Archived',
        },
    },
    adminUserSaveValidationError: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'save', 'validation'],
        translations: {
            'de-DE': 'Bitte füllen Sie alle Pflichtfelder aus.',
            'en-GB': 'Please fill in all mandatory fields.',
        },
    },
    adminUsersButtonSavedSuccessfully: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUsers,
        tags: ['static', 'admin', 'users', 'button', 'save', 'success'],
        translations: {
            'de-DE': 'Benutzerfelder gespeichert',
            'en-GB': 'User fields saved',
        },
    },
};
