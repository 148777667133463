import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const controlsTranslation: ITranslationMap = {
    controlTextIsRequired: {
        context: undefined,
        group: ETranslationGroups.control,
        tags: ['static', 'control', 'required'],
        translations: {
            'de-DE': 'Pflichtfeld',
            'en-GB': 'Field is required',
        },
    },
};
