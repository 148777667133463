export enum ETranslationGroups {
    profile = 'profile',
    cart = 'cart',
    checkout = 'checkout',
    common = 'common',
    error = 'error',
    footer = 'footer',
    header = 'header',
    login = 'login',
    navigation = 'navigation',
    product = 'product',
    search = 'search',
    billingAddress = 'billingAddress',
    orderAddress = 'orderAddress',
    theme = 'theme',
    toast = 'toast',
    confirm = 'confirm',
    cmsPage = 'cmsPage',
    adminProduct = 'adminProduct',
    adminNavigation = 'adminNavigation',
    control = 'control',
    adminLocalization = 'adminLocalization',
    adminTable = 'adminTable',
    adminTranslations = 'adminTranslations',
    adminUserfields = 'adminUserfields',
    adminProductfields = 'adminProductfields',
    adminRoles = 'adminRoles',
    adminUsers = 'adminUsers',
    adminCategories = 'adminCategories',
    test = 'test',
}
