import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminLocalizationTranslation: ITranslationMap = {
    adminLocalizationHeadlineLanguagesAndLocalization: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminLocalization,
        tags: ['static', 'admin', 'localization', 'headline'],
        translations: {
            'de-DE': 'Sprachen & Lokalisierung',
            'en-GB': 'Languages & Localization',
        },
    },
    adminLocalizationDescriptionLanguagesAndLocalization: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminLocalization,
        tags: ['static', 'admin', 'localization', 'description'],
        translations: {
            'de-DE':
                'Hier können Sie die verfügbaren Sprachen einstellen, sowie die Notationen für Preise und Beträge.',
            'en-GB':
                'Here you can set the available languages as well as the notations for prices and amounts.',
        },
    },
    adminLocalizationButtonAddLocalization: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminLocalization,
        tags: ['static', 'admin', 'localization', 'button'],
        translations: {
            'de-DE': 'Lokalisierung hinzufügen',
            'en-GB': 'Add localization',
        },
    },
    adminLocalizationTableLocalization: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminLocalization,
        tags: ['static', 'admin', 'localization', 'table'],
        translations: {
            'de-DE': 'Lokalisierung',
            'en-GB': 'Localization',
        },
    },
    adminLocalizationTableLocale: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminLocalization,
        tags: ['static', 'admin', 'localization', 'table'],
        translations: {
            'de-DE': 'Standort',
            'en-GB': 'Locale',
        },
    },
    adminLocalizationTableNotation: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminLocalization,
        tags: ['static', 'admin', 'localization', 'table'],
        translations: {
            'de-DE': 'Notation',
            'en-GB': 'Notation',
        },
    },
    adminLocalizationTableDefault: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminLocalization,
        tags: ['static', 'admin', 'localization', 'table'],
        translations: {
            'de-DE': 'Standard',
            'en-GB': 'Default',
        },
    },
    adminLocalizationTableActions: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminLocalization,
        tags: ['static', 'admin', 'localization', 'table'],
        translations: {
            'de-DE': 'Aktionen',
            'en-GB': 'Actions',
        },
    },
};
