import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const orderAddressTranslation: ITranslationMap = {
    orderAddressLabelCompanyName: {
        context: undefined,
        group: ETranslationGroups.orderAddress,
        tags: ['static', 'address', 'label'],
        translations: {
            'de-DE': 'Firmierung',
            'en-GB': 'Company name',
        },
    },

    orderAddressLabelName: {
        context: undefined,
        group: ETranslationGroups.orderAddress,
        tags: ['static', 'address', 'label'],
        translations: {
            'de-DE': 'Name',
            'en-GB': 'Name',
        },
    },

    orderAddressLabelPhone: {
        context: undefined,
        group: ETranslationGroups.orderAddress,
        tags: ['static', 'address', 'label'],
        translations: {
            'de-DE': 'Telefon',
            'en-GB': 'Phone',
        },
    },

    orderAddressLabelStreetAndHouseNo: {
        context: undefined,
        group: ETranslationGroups.orderAddress,
        tags: ['static', 'address', 'label'],
        translations: {
            'de-DE': 'Straße u. Hausnr.',
            'en-GB': 'Street and house no.',
        },
    },

    orderAddressLabelPostCodeAndCity: {
        context: undefined,
        group: ETranslationGroups.orderAddress,
        tags: ['static', 'address', 'label'],
        translations: {
            'de-DE': 'PLZ und Ort',
            'en-GB': 'Postcode and city',
        },
    },
    orderAddressLabelCountry: {
        context: undefined,
        group: ETranslationGroups.orderAddress,
        tags: ['static', 'address', 'label'],
        translations: {
            'de-DE': 'Land',
            'en-GB': 'Country',
        },
    },
};
