import React, { useMemo } from 'react';
import { Modal } from '../../modals/Modal';
import { useConfirmPromptState } from './state/confirmPrompt.state';
import { Button } from '../../controls/button/Button';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { ETranslationType } from '../../Translations/enum/translations.enums';
import { ETranslationEntity } from '../../Translations/types/translationApply.types';
import { useTranslation } from '../../Translations/hooks/useTranslation';

interface IConfirmPrompt {}

export const ConfirmPrompt: React.FC<IConfirmPrompt> = () => {
    const { onConfirm, onCancel, show, setShow, text, translationKey } = useConfirmPromptState();

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const translatedText = useMemo(() => {
        if (!translationKey) return text;

        return getT(translationKey) ?? '';
    }, [getT, text, translationKey]);

    const translationApply = useMemo(() => {
        if (!translationKey) return undefined;

        return applyTranslation({
            key: translationKey,
            type: ETranslationType.static,
            entity: ETranslationEntity.text,
        });
    }, [applyTranslation, translationKey]);

    return (
        <Modal
            show={show}
            maxWidthPreset={'md'}
            titleTranslationKey="promptHeadline"
            afterClose={() => {
                setShow(false);
            }}
        >
            <div className="relative flex w-full flex-col gap-8">
                <div {...translationApply}>{translatedText}</div>

                <div className="relative grid grid-cols-2 gap-4">
                    <Button
                        color="neutral"
                        onClick={onCancel}
                        wrapperCss="w-full"
                        translationKey="promptModalCancel"
                        isTranslatable={false}
                    />
                    <Button
                        color="success"
                        onClick={onConfirm}
                        wrapperCss="w-full"
                        additionalCss="w-full"
                        translationKey="promptModalConfirm"
                        isTranslatable={false}
                    />
                </div>
            </div>
        </Modal>
    );
};
