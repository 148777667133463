import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminTranslationsTranslation: ITranslationMap = {
    adminTranslationsHeadlineTranslations: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminTranslations,
        tags: ['static', 'admin', 'translations', 'header'],
        translations: {
            'de-DE': 'Übersetzungen',
            'en-GB': 'Translations',
        },
    },
    adminTranslationsDescriptionTranslations: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminTranslations,
        tags: ['static', 'admin', 'translations', 'description'],
        translations: {
            'de-DE':
                'Hier können Sie die Übersetzungen für die statischen Schlüsselwörter einstellen.',
            'en-GB': 'Here you can set the translations for the static keywords.',
        },
    },
    adminTranslationsPlaceholderWhatAreYouLookingFor: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminTranslations,
        tags: ['static', 'admin', 'translations', 'placeholder'],
        translations: {
            'de-DE': 'Was suchen Sie?',
            'en-GB': 'What are you looking for?',
        },
    },
    // adminTranslations: {
    //     context: undefined,
    //     locked: true,
    //     group: ETranslationGroups.adminTranslations,
    //     tags: ['static', 'admin', 'translations', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
