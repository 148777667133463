import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const searchTranslations: ITranslationMap = {
    infoTypeToSearch: {
        context: 'Searchfield. Technical field',
        locked: true,
        group: ETranslationGroups.adminProduct,
        tags: ['static', 'search', 'info'],
        translations: {
            'de-DE': 'Gebe im Suchfeld einen Suchbegriff ein, um die Suche zu starten.',
            'en-GB': 'Enter a search term to start the search.',
        },
    },
    infoNoProductsFound: {
        context: 'Searchfield. Technical field',
        locked: true,
        group: ETranslationGroups.adminProduct,
        tags: ['static', 'search', 'info'],
        translations: {
            'de-DE': 'Keine Produkte gefunden.',
            'en-GB': 'No products found.',
        },
    },
};
