import { get } from 'lodash';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { computedStylesRecoil } from '../../ComputedStyles/recoil/computedStyles.recoil';
import { IUIConfig } from '../../ComputedStyles/interfaces/computedStyles.interface';

export interface IThemeStylesConfig {
    textColor?: string;
    bgColor?: string;
    rounded?: IUIConfig['border']['radius'] | 'auto';
    borderColor?: string;
    borderWidth?: IUIConfig['border']['width'] | 'auto';
}

export const useThemeStyles = () => {
    const uiConfig = useRecoilValue(computedStylesRecoil).theme;

    const applyStyles = useCallback(
        (config: IThemeStylesConfig): React.CSSProperties | undefined => {
            if (!uiConfig) return undefined;

            const cssStyles = {} as React.CSSProperties;

            if (config.textColor) {
                if (config.textColor.startsWith('rgb') || config.textColor.startsWith('#')) {
                    cssStyles.color = config.textColor;
                } else {
                    cssStyles.color = get(uiConfig, config.textColor) as string;
                }
            }

            if (config.bgColor) {
                if (config.bgColor.startsWith('rgb') || config.bgColor.startsWith('#')) {
                    cssStyles.backgroundColor = config.bgColor;
                } else {
                    cssStyles.backgroundColor = get(uiConfig, config.bgColor) as string;
                }
            }

            if (config.borderColor) {
                cssStyles.borderColor = get(uiConfig, config.borderColor) as string;
            }

            if (config.rounded) {
                if (config.rounded === 'auto' || config.rounded) {
                    const configValue: string = get(uiConfig, `border.radius`) as string;

                    switch (configValue) {
                        case 'off':
                            cssStyles.borderRadius = '0px';
                            break;
                        case 'light':
                            cssStyles.borderRadius = '4px';
                            break;
                        case 'strong':
                            cssStyles.borderRadius = '12px';
                            break;
                        default:
                            cssStyles.borderRadius = configValue;
                    }
                }

                if (config.rounded === 'off') {
                    cssStyles.borderRadius = '0px';
                }

                if (config.rounded === 'light') {
                    cssStyles.borderRadius = '4px';
                }

                if (config.rounded === 'strong') {
                    cssStyles.borderRadius = '12px';
                }
            }

            if (config.borderWidth) {
                if (config.borderWidth === 'auto' || config.borderWidth) {
                    const configValue = get(uiConfig, `border.width`) as string;

                    switch (configValue) {
                        case 'off':
                            cssStyles.borderWidth = '0px';
                            break;
                        case 'thin':
                            cssStyles.borderWidth = '1px';
                            break;
                        case 'thick':
                            cssStyles.borderWidth = '2px';
                            break;
                        default:
                            cssStyles.borderWidth = configValue;
                    }
                }

                if (config.borderWidth === 'off') {
                    cssStyles.borderWidth = '0px';
                }

                if (config.borderWidth === 'thin') {
                    cssStyles.borderWidth = '1px';
                }

                if (config.borderWidth === 'thick') {
                    cssStyles.borderWidth = '2px';
                }
            }

            return cssStyles;
        },
        [uiConfig]
    );

    return { applyStyles, uiConfig };
};
