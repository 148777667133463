import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const footerTranslations: ITranslationMap = {
    footerContactUs: {
        context: undefined,
        group: ETranslationGroups.footer,
        tags: ['static', 'contact'],
        translations: {
            'de-DE': 'Kontaktieren Sie uns!',
            'en-GB': 'Contact us!',
        },
    },

    footerAnyQuestionsLabel: {
        context: undefined,
        group: ETranslationGroups.footer,
        tags: ['static', 'contact'],
        translations: {
            'de-DE': 'Haben Sie Fragen?',
            'en-GB': 'Do you have questions?',
        },
    },

    footerImprint: {
        context: undefined,
        group: ETranslationGroups.footer,
        tags: ['static', 'legal'],
        translations: {
            'de-DE': 'Impressum',
            'en-GB': 'Imprint',
        },
    },

    footerPrivacyPolicy: {
        context: undefined,
        group: ETranslationGroups.footer,
        tags: ['static', 'legal'],
        translations: {
            'de-DE': 'Datenschutz',
            'en-GB': 'Privacy Policy',
        },
    },

    footerPrivacyGtc: {
        context: undefined,
        group: ETranslationGroups.footer,
        tags: ['static', 'legal'],
        translations: {
            'de-DE': 'AGB',
            'en-GB': 'GTC',
        },
    },
};
