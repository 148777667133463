import { atom, DefaultValue, selector } from 'recoil';
import { nanoid } from 'nanoid';
import { IUIConfig } from '../interfaces/computedStyles.interface';

// const detaultTheme = defaultTenantTheme();

export interface IComputedStyles {
    tenantName: string | null;
    theme: IUIConfig | null;
    manager: {
        overwriteColors: boolean;
    };
}

export const computedStylesRecoil = atom<IComputedStyles>({
    key: `computed-styles-state/${nanoid()}`,
    default: {
        tenantName: null,
        theme: null,
        // theme: detaultTheme,
        manager: {
            overwriteColors: true,
        },
    },
});

// Important: This selector is not supposed to use before provider
export const getTenantTheme = selector<IUIConfig>({
    key: `get-tenant-style`,
    get: ({ get }): IUIConfig => {
        return get(computedStylesRecoil).theme as IUIConfig;
    },
    set: ({ set }, newValue) => {
        set(
            computedStylesRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, theme: newValue };
                  }
        );
    },
});

export const getManagerOverwriteColor = selector<boolean>({
    key: `getManagerOverwriteColors`,
    get: ({ get }): boolean => {
        return get(computedStylesRecoil).manager.overwriteColors;
    },
});

export const getTenantName = selector<string | null>({
    key: `getTenantName`,
    get: ({ get }): string | null => {
        return get(computedStylesRecoil).tenantName;
    },
    set: ({ set }, newValue) => {
        set(
            computedStylesRecoil,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, tenantName: newValue };
                  }
        );
    },
});
