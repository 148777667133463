import { useCallback } from 'react';
import { ITranslations } from 'src/interfaces/translation/translations.interfaces';
import { useLocalisationState } from '../state/localisation.state';

export const useTranslationGetContent = () => {
    const { userCurrentLanguage, tenantDefaultLanguage } = useLocalisationState();

    const getTranslationContent = useCallback(
        (translation?: ITranslations, defaultValue = '', forceDefaultLanguage = false): string => {
            if (!translation) return defaultValue;

            // Determine which language to use
            const languageToUse = forceDefaultLanguage
                ? tenantDefaultLanguage
                : userCurrentLanguage;

            if (languageToUse?.default) {
                // For default language, use baseTranslation
                return translation.baseTranslation ?? defaultValue;
            } else {
                // For non-default languages, find the appropriate translation
                const translationItem = translation.translations?.find(
                    (t) => t.languageCode === languageToUse?.languageCode
                );
                return translationItem?.translation ?? defaultValue;
            }
        },
        [userCurrentLanguage, tenantDefaultLanguage]
    );

    return { getTranslationContent };
};
