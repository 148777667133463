import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const headerTranslation: ITranslationMap = {
    headerSearchPlaceholder: {
        context: undefined,
        group: ETranslationGroups.header,
        tags: ['static', 'header', 'navigation', 'placeholder'],
        translations: {
            'de-DE': 'Durchsuchen',
            'en-GB': 'Search',
        },
    },
    headerFavouriteButtonTip: {
        context: undefined,
        group: ETranslationGroups.header,
        tags: ['static', 'header', 'navigation', 'button', 'tip'],
        translations: {
            'de-DE': 'Meine Favoriten',
            'en-GB': 'My favourites',
        },
    },
    headerCartButtonTip: {
        context: undefined,
        group: ETranslationGroups.header,
        tags: ['static', 'header', 'navigation', 'button', 'tip'],
        translations: {
            'de-DE': 'Zum Warenkorb',
            'en-GB': 'To the shopping cart',
        },
    },
    headerWelcome: {
        context: "Welcome label. Shall be very short, e.g. 'Welcome'",
        group: ETranslationGroups.header,
        tags: ['static', 'header', 'navigation', 'user'],
        translations: {
            'de-DE': 'Herzlich willkommen',
            'en-GB': 'Welcome',
        },
    },
};
