import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminProductfieldsTranslations: ITranslationMap = {
    adminProductfieldsHeaderProductFields: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'header', 'headline'],
        translations: {
            'de-DE': 'Produktfelder',
            'en-GB': 'Product fields',
        },
    },
    adminProductfieldsDescriptionProductFields: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'description'],
        translations: {
            'de-DE': 'Hier können Sie die Produktfelder verwalten.',
            'en-GB': 'You can manage the product fields here.',
        },
    },
    adminProductfieldsMetaHeaderProductFields: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'header', 'headline'],
        translations: {
            'de-DE': 'Produkt Metafelder',
            'en-GB': 'Product meta fields',
        },
    },
    adminProductfieldsMetaDescriptionProductFields: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'description'],
        translations: {
            'de-DE': 'Hier können Sie die Produkt Metafelder verwalten.',
            'en-GB': 'You can manage the product meta fields here.',
        },
    },
    adminProductfieldsTableFieldName: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'table'],
        translations: {
            'de-DE': 'Feldname',
            'en-GB': 'Field name',
        },
    },
    adminProductfieldsTableLabel: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'table'],
        translations: {
            'de-DE': 'Label',
            'en-GB': 'Label',
        },
    },
    adminProductfieldsTableType: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'table'],
        translations: {
            'de-DE': 'Typ',
            'en-GB': 'Type',
        },
    },
    adminProductfieldsButtonCreate: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'button'],
        translations: {
            'de-DE': 'Anlegen',
            'en-GB': 'Create',
        },
    },
    adminProductfieldsPlaceholderNewFieldName: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProductfields,
        tags: ['static', 'admin', 'productfields', 'placeholder'],
        translations: {
            'de-DE': 'Neuer Feldname',
            'en-GB': 'New field name',
        },
    },
    // adminProductfields: {
    //     context: undefined,
    //     locked: true,
    //     group: ETranslationGroups.adminProductfields,
    //     tags: ['static', 'admin', 'productfields', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
