import createStateContext from 'react-use/lib/factory/createStateContext';
import { useLatest } from 'react-use';
import { usePersistentContext } from './usePersistentContext';

/**
 * name and value types of all fields used in form filling
 */
export interface IFormContext {
    autoMode?: boolean;
}

export const DefaultFieldsValues: IFormContext = {
    autoMode: true,
};

/**
 * Main FormFilling context.
 * Creates a shared state context that you can use with `useField` hook to R/W to individual fields
 */
export const [useBLFormContextSettings, BLFormContextSettingsProvider] =
    createStateContext<IFormContext>(DefaultFieldsValues);

export const useFormContextPersisted = (id = 'formSettings') => {
    const [dataContext, setDataContext] = useBLFormContextSettings();

    const dataLatest = useLatest(dataContext);

    const { persistentData: formSettings, setPersistentData: setFormSettings } =
        usePersistentContext<IFormContext>(`form-${id}`, dataLatest.current, setDataContext);

    return { formSettings, setFormSettings };
};
