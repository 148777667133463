import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const billingAddressTranslation: ITranslationMap = {
    billingAddressHeadline: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'headline'],
        translations: {
            'de-DE': 'Rechnungsadresse',
            'en-GB': 'Billing address',
        },
    },

    billingAddressInfobox: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'infobox'],
        translations: {
            'de-DE': 'Folgende Angaben werden ausschließlich für das Rechnungswesen verwendet.',
            'en-GB': 'The following information is used exclusively for accounting purposes.',
        },
    },

    billingAddressSalutationLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Anrede',
            'en-GB': 'Salutation',
        },
    },

    billingAddressCountryLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Land',
            'en-GB': 'Country',
        },
    },

    billingAddressTitleLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Titel',
            'en-GB': 'Title',
        },
    },
    billingAddressTitlePlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': 'Dr. Ing.',
            'en-GB': 'PhD',
        },
    },

    billingAddressFirstnameLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Vorname',
            'en-GB': 'First name',
        },
    },
    billingAddressFirstnamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': 'Max',
            'en-GB': 'John',
        },
    },

    billingAddressSurnameLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Nachname',
            'en-GB': 'Surname',
        },
    },
    billingAddressSurnamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': 'Musterman',
            'en-GB': 'Smith',
        },
    },

    billingAddressCompanynameLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Firmenname',
            'en-GB': 'Company name',
        },
    },
    billingAddressCompanynamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': 'Firmenname GmbH',
            'en-GB': 'Company name Ltd.',
        },
    },

    billingAddressPhoneLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Telefon',
            'en-GB': 'Phone',
        },
    },
    billingAddressPhonePlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': '+49 1234 567 890',
            'en-GB': '+44 1234 567 890',
        },
    },

    billingAddressEmailLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Email',
            'en-GB': 'e-mail',
        },
    },
    billingAddressEmailPlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': 'muster@muster.de',
            'en-GB': 'john@email.uk',
        },
    },

    billingAddressEmailccLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'CC',
            'en-GB': 'Recipient e-mail',
        },
    },
    billingAddressEmailccPlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': 'muster@cc-email.de',
            'en-GB': 'recipient@email.uk',
        },
    },

    billingAddressStreetandhousenoLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Strasse und Hausnummer',
            'en-GB': 'Street and house number',
        },
    },
    billingAddressStreetandhousenoPlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': 'Musterstrasse 34',
            'en-GB': 'Street 34',
        },
    },

    billingAddressZipcodeLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'PLZ',
            'en-GB': 'Zip code',
        },
    },
    billingAddressZipcodePlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': '10117',
            'en-GB': 'N6A2C5',
        },
    },

    billingAddressCityLabel: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'label'],
        translations: {
            'de-DE': 'Ort',
            'en-GB': 'City',
        },
    },
    billingAddressCityPlaceholder: {
        context: undefined,
        group: ETranslationGroups.billingAddress,
        tags: ['static', 'billingAddress', 'placeholder'],
        translations: {
            'de-DE': 'Musterstadt',
            'en-GB': 'London',
        },
    },
};
