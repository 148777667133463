import { create } from 'zustand';
import { ILocale } from '../../../../interfaces/localisation/locale.interfaces';

interface ILocalisationState {
    userCurrentLanguage: ILocale | null;
    userLanguageOptions: ILocale[];
    tenantDefaultLanguage: ILocale | null;
}

interface ILocalisationAction {
    updateUserCurrentLanguage: (language: ILocalisationState['userCurrentLanguage']) => void;
    updateUserLanguageOptions: (options: ILocalisationState['userLanguageOptions']) => void;
    updateTenantDefaultLanguage: (language: ILocalisationState['tenantDefaultLanguage']) => void;
    getCurrentLanguage: () => ILocale;
}

export const useLocalisationState = create<ILocalisationState & ILocalisationAction>(
    (set, get) => ({
        userCurrentLanguage: null,
        userLanguageOptions: [],
        tenantDefaultLanguage: null,

        getCurrentLanguage: () =>
            get().userCurrentLanguage ||
            get().tenantDefaultLanguage ||
            ({ languageCode: 'de-DE', default: false } as ILocale),

        updateUserCurrentLanguage: (language) => set(() => ({ userCurrentLanguage: language })),
        updateUserLanguageOptions: (options) => set(() => ({ userLanguageOptions: options })),
        updateTenantDefaultLanguage: (language) => set(() => ({ tenantDefaultLanguage: language })),
    })
);
