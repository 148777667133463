import { create } from 'zustand';

interface IAdminControlsState {
    showFAB: boolean;
    setShowFAB: (state: boolean) => void;
    set: (state: Partial<IAdminControlsState>) => void;
    languageControls: boolean;
    setLanguageControls: (state: boolean) => void;
    productControls: boolean;
    setProductControls: (state: boolean) => void;
    cmsControls: boolean;
    setCmsControls: (state: boolean) => void;
    reset: () => void;
    saveControlsState: () => void;
    restoreControlsState: () => void;
    previousState: {
        languageControls: boolean;
        productControls: boolean;
        cmsControls: boolean;
    };
}

export const useAdminControlsState = create<IAdminControlsState>((set, get) => ({
    showFAB: false,
    set: (state) => {
        set(state);
    },
    setShowFAB: (state) => {
        set({ showFAB: state });
        localStorage.setItem('showFAB', state.toString());
    },
    languageControls: false,
    setLanguageControls: (state) => {
        set({ languageControls: state });
        localStorage.setItem('languageControls', state.toString());
    },
    productControls: false,
    setProductControls: (state) => {
        set({ productControls: state });
        localStorage.setItem('productControls', state.toString());
    },
    cmsControls: false,
    setCmsControls: (state) => {
        set({ cmsControls: state });
        localStorage.setItem('cmsControls', state.toString());
    },
    previousState: {
        languageControls: false,
        productControls: false,
        cmsControls: false,
    },
    saveControlsState: () => {
        const state = get();
        // Save current control states to localStorage with a "previous" prefix
        localStorage.setItem('previousLanguageControls', state.languageControls.toString());
        localStorage.setItem('previousProductControls', state.productControls.toString());
        localStorage.setItem('previousCmsControls', state.cmsControls.toString());
        
        // Also save to state for redundancy
        set({
            previousState: {
                languageControls: state.languageControls,
                productControls: state.productControls,
                cmsControls: state.cmsControls,
            }
        });
    },
    restoreControlsState: () => {
        // First try to get values from localStorage
        const prevLanguageControls = localStorage.getItem('previousLanguageControls') === 'true';
        const prevProductControls = localStorage.getItem('previousProductControls') === 'true';
        const prevCmsControls = localStorage.getItem('previousCmsControls') === 'true';
        
        // Set control states
        set({
            languageControls: prevLanguageControls,
            productControls: prevProductControls,
            cmsControls: prevCmsControls,
        });
        
        // Update localStorage with restored values
        localStorage.setItem('languageControls', prevLanguageControls.toString());
        localStorage.setItem('productControls', prevProductControls.toString());
        localStorage.setItem('cmsControls', prevCmsControls.toString());
    },
    reset: () => {
        // First save current state before resetting
        const state = get();
        localStorage.setItem('previousLanguageControls', state.languageControls.toString());
        localStorage.setItem('previousProductControls', state.productControls.toString());
        localStorage.setItem('previousCmsControls', state.cmsControls.toString());
        
        // Update previous state in memory too
        set({
            previousState: {
                languageControls: state.languageControls,
                productControls: state.productControls,
                cmsControls: state.cmsControls,
            },
            // Then reset all controls
            showFAB: false,
            languageControls: false,
            productControls: false,
            cmsControls: false,
        });
        
        // Clear localStorage (except for previous state values)
        localStorage.removeItem('showFAB');
        localStorage.removeItem('languageControls');
        localStorage.removeItem('productControls');
        localStorage.removeItem('cmsControls');
    },
}));
