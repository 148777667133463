import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiState } from '../../api/state/api.state';
import { useLatest } from 'react-use';
import { useApiRefresh } from './useApiRefresh';
import { usePathname } from 'next/navigation';
import { useCallback } from 'react';
import { apiGet, apiPost } from '../../api/api';
import axios, { AxiosError } from 'axios';
import { getRedirectUrl } from '../../Auth/authUtil';
import { IGQLRequest, IGQLReturn } from '../../../../interfaces/gql/request';

export const useApi = () => {
    const { isRefreshing } = useRecoilValue(apiState);
    const setApiState = useSetRecoilState(apiState);
    const isRefreshingLatest = useLatest<boolean>(isRefreshing);
    const { refresh } = useApiRefresh();

    const pathname = usePathname();

    const get = useCallback(
        async <T>(path: string) => {
            const result = await apiGet<T>(path).catch(async (e: AxiosError) => {
                if (!isRefreshingLatest.current) {
                    // if (await refresh()) {
                    if (refresh()) {
                        return apiGet<T>(path);
                    }
                }
                return e;
            });

            if (result) {
                if (axios.isAxiosError(result)) {
                    throw result;
                }
                return result;
            } else {
                if (!pathname.startsWith('/signup')) {
                    // Avoid router here because of testability and coupling
                    window.location.href = getRedirectUrl();
                    setApiState({ isRefreshing: false });
                }
                return false;
            }
        },
        [isRefreshingLatest, pathname, refresh, setApiState]
    );

    const post = useCallback(
        async <T, G>(path: string, data?: G) => {
            const result = await apiPost<T, G>(path, data).catch(async (e: AxiosError) => {
                if (!isRefreshingLatest.current) {
                    // if (await refresh()) {
                    if (refresh()) {
                        return apiPost<T, G>(path, data);
                    }
                }
                return e;
            });

            if (result) {
                if (axios.isAxiosError(result)) {
                    throw result;
                }
                return result;
            } else {
                if (!pathname.startsWith('/signup')) {
                    // Avoid router here because of testability and coupling
                    window.location.href = getRedirectUrl();
                    setApiState({ isRefreshing: false });
                }
                return false;
            }
        },
        [isRefreshingLatest, pathname, refresh, setApiState]
    );

    const postGql = useCallback(
        async <T>(queryName: string, query: string, variables?: unknown) => {
            const result = await apiPost<IGQLReturn<T>, IGQLRequest>(`/graphql?c=${queryName}`, {
                query,
                variables,
            });

            if (result) {
                if (result.errors) {
                    switch (result.errors[0]?.statusCode) {
                        case 404:
                            throw new Error('404');
                        case 401:
                            if (!isRefreshingLatest.current) {
                                await refresh();
                            }
                            throw new Error();
                        default:
                            if (result.errors[0]?.message === 'Unauthorized') {
                                if (!isRefreshingLatest.current) {
                                    await refresh();
                                }
                                throw new Error();
                            } else {
                                throw new Error(result.errors[0]?.message);
                            }
                    }
                }
                return result.data;
            } else {
                throw new Error();
            }
        },
        [isRefreshingLatest, refresh]
    );

    const postUpload = useCallback(
        async <Type>(
            query: string,
            variables: { [key: string]: unknown },
            map: { [key: string]: string[] },
            files: File[]
        ): Promise<Type> => {
            const formData = new FormData();
            formData.append('operations', JSON.stringify({ query, variables }));
            formData.append('map', JSON.stringify(map));

            files.forEach((file, index) => {
                formData.append(String(index), file);
            });

            const result = await apiPost<IGQLReturn<Type>, FormData>(
                '/graphql',
                formData,
                files.length > 0
                    ? {
                          'Content-Type': 'multipart/form-data',
                          'x-apollo-operation-name': 'fileUpload',
                      }
                    : undefined
            );
            if (result) {
                if (result.errors) {
                    switch (result.errors[0]?.statusCode) {
                        case 404:
                            throw new Error('404');
                        case 401:
                            if (!isRefreshingLatest.current) {
                                await refresh();
                            }
                            throw new Error();
                        default:
                            throw new Error(String(result.errors[0]?.statusCode));
                    }
                }
                return result.data;
            } else {
                throw new Error();
            }
        },
        [isRefreshingLatest, refresh]
    );

    return { get, post, postGql, postUpload };
};
