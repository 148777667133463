import { create } from 'zustand';

interface IConfirmPromptState {
    show: boolean;
    setShow: (show: boolean) => void;
    text: string;
    setText: (text: string) => void;
    onConfirm: () => void;
    setOnConfirm: (onConfirm: () => void) => void;
    onCancel: () => void;
    setOnCancel: (onCancel: () => void) => void;
    set: (state: Partial<IConfirmPromptState>) => void;
    translationKey?: string;
    setTranslationKey?: (translationKey: string | undefined) => void;
}

export const useConfirmPromptState = create<IConfirmPromptState>((set) => ({
    show: false,
    setShow: (show) => {
        set({ show });
    },
    text: '',
    setText: (text) => {
        set({ text });
    },
    onConfirm: () => {},
    setOnConfirm: (onConfirm) => {
        set({ onConfirm });
    },
    onCancel: () => {},
    setOnCancel: (onCancel) => {
        set({ onCancel });
    },
    set: (state) => {
        set(state);
    },
    translationKey: undefined,
    setTranslationKey: (translationKey) => {
        set({ translationKey });
    },
}));
