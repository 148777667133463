import { create } from 'zustand';
import { ITranslationLocales, ITranslationMap } from '../../../../data/translationMapStatic';
import { staticFallbackPattern } from '../../Translations/helper/staticFallbackPattern';

/**
 *
 * // TODO Group, context
 * export interface ITranslationMap {
 *     [key: string]: {
 *         translations: ITranslationLocales;
 *         group?: string;
 *         context?: string;
 *     };
 * }
 */

interface ITranslationState {
    translations: ITranslationMap;
    setAllTranslations: (translations: ITranslationMap) => void;
    setTranslation: (key: string, value: ITranslationLocales) => void;
    setTranslationKey: (key: string, locale: string, value: string) => void;
    set: (state: Partial<ITranslationState>) => void;
    lastUpdated: number;
    setLastUpdated: (lastUpdated: number) => void;
    getTranslation: (key: string) => ITranslationLocales | undefined;
    getTranslationByLocale: (key: string, locale: string, defaultLocale?: string) => string | null;
}

export const useTranslationState = create<ITranslationState>((set, get) => ({
    translations: {},
    setAllTranslations: (translations) => {
        set({ translations });
    },
    setTranslation: (key, translationLocales) => {
        set((state) => {
            const newTranslations = { ...state.translations };
            const instance = newTranslations?.[key];
            if (instance) {
                instance.translations = translationLocales;
            }
            return { translations: newTranslations };
        });
    },

    setTranslationKey: (key: string, locale: string, value: string) => {
        set((state) => {
            const newTranslations = { ...state.translations };
            const instance = newTranslations?.[key];
            if (instance) {
                instance.translations[locale] = value;
            }
            return { translations: newTranslations };
        });
    },
    set: (state) => {
        set(state);
    },
    lastUpdated: 0,
    setLastUpdated: (lastUpdated) => {
        set({ lastUpdated });
    },
    getTranslation: (key) => {
        return get().translations?.[key]?.translations;
    },
    getTranslationByLocale: (key, locale, defaultLocale?: string) => {
        const translations = get().translations?.[key]?.translations;
        if (!translations) return null;

        // Try to get translation for requested locale
        const translation = translations[locale];

        // If translation exists, return it
        if (translation !== undefined) {
            return translation;
        }

        // If not, check for fallback locale from static pattern
        const fallbackLocale = staticFallbackPattern[locale];
        if (fallbackLocale && translations[fallbackLocale] !== undefined) {
            return translations[fallbackLocale];
        }
        
        // If static fallback pattern isn't defined or translation not found,
        // use the provided defaultLocale as final fallback
        if (defaultLocale && translations[defaultLocale] !== undefined) {
            return translations[defaultLocale];
        }

        return null;
    },
}));
