import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminRolesTranslations: ITranslationMap = {
    adminRolesHeaderRolesAndRights: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminRoles,
        tags: ['static', 'admin', 'roles', 'header', 'headline'],
        translations: {
            'de-DE': 'Rollen & Rechte',
            'en-GB': 'Roles & rights',
        },
    },
    adminRolesDescriptionRoles: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminRoles,
        tags: ['static', 'admin', 'roles', 'description'],
        translations: {
            'de-DE': 'Hier können Sie Rollen und Rechte verwalten.',
            'en-GB': 'You can manage roles and rights here.',
        },
    },
    adminRolesTableName: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminRoles,
        tags: ['static', 'admin', 'roles', 'table'],
        translations: {
            'de-DE': 'Name',
            'en-GB': 'Name',
        },
    },
    adminRolesTableDescription: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminRoles,
        tags: ['static', 'admin', 'roles', 'table'],
        translations: {
            'de-DE': 'Beschreibung',
            'en-GB': 'Description',
        },
    },
    adminRolesTableType: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminRoles,
        tags: ['static', 'admin', 'roles', 'table'],
        translations: {
            'de-DE': 'Typ',
            'en-GB': 'Type',
        },
    },
    adminRolesTableRights: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminRoles,
        tags: ['static', 'admin', 'roles', 'table'],
        translations: {
            'de-DE': 'Rechte',
            'en-GB': 'Rights',
        },
    },
    adminRolesButtonCreate: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminRoles,
        tags: ['static', 'admin', 'roles', 'button'],
        translations: {
            'de-DE': 'Anlegen',
            'en-GB': 'Create',
        },
    },
    adminRolesPlaceholderNewRole: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminRoles,
        tags: ['static', 'admin', 'roles', 'placeholder'],
        translations: {
            'de-DE': 'Neue Rolle',
            'en-GB': 'New role',
        },
    },
    // adminRoles: {
    //     context: undefined,
    //     locked: true,
    //     group: ETranslationGroups.adminRoles,
    //     tags: ['static', 'admin', 'roles', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
