import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const promptModalTranslation: ITranslationMap = {
    promptModalCancel: {
        context: 'Short button label.',
        group: ETranslationGroups.adminCategories,
        tags: ['static', 'modal', 'button'],
        translations: {
            'de-DE': 'Abbruch',
            'en-GB': 'Cancel',
        },
    },
    promptModalConfirm: {
        context: 'Short button label.',
        group: ETranslationGroups.adminCategories,
        tags: ['static', 'modal', 'button'],
        translations: {
            'de-DE': 'Bestätigen',
            'en-GB': 'Confirm',
        },
    },
    promptHeadline: {
        context: 'Short Headline for a Modal Confirm.',
        group: ETranslationGroups.adminCategories,
        tags: ['static', 'modal', 'headline'],
        translations: {
            'de-DE': 'Bestätigung',
            'en-GB': 'Confirmation',
        },
    },
};
