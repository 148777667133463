import { useSetRecoilState } from 'recoil';
import { apiState } from '../../api/state/api.state';
import { usePathname } from 'next/navigation';
import { useCallback } from 'react';
import { refresh } from '../../api/auth/refresh';
import { getRedirectUrl } from '../../Auth/authUtil';

export const useApiRefresh = () => {
    const setRequestState = useSetRecoilState(apiState);
    const pathname = usePathname();

    const refreshCB = useCallback(() => {
        // Add formtest for development
        if (!pathname.startsWith('/signup') && !pathname.startsWith('/formtest')) {
            setRequestState((context) => {
                return { ...context, isRefreshing: true };
            });

            return refresh()
                .then(() => {
                    setRequestState((context) => {
                        return {
                            ...context,
                            isRefreshing: false,
                        };
                    });
                    return true;
                })
                .catch(() => {
                    // Avoid router here because of testability and coupling
                    window.location.href = getRedirectUrl();
                    return false;
                });
        }
        return false;
    }, [pathname, setRequestState]);

    return { refresh: refreshCB };
};
