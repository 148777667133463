import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminProductDetails: ITranslationMap = {
    adminProductDetailsStockInfo: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProduct,
        tags: ['static', 'admin', 'product', 'info'],
        translations: {
            'de-DE': 'Achtung: Bestandseinstellungen werden direkt gespeichert.',
            'en-GB': 'Note: Stock settings are saved directly.',
        },
    },
    adminProductDetailsStockSaved: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProduct,
        tags: ['static', 'admin', 'product', 'toast'],
        translations: {
            'de-DE': 'Bestandseinstellungen gespeichert',
            'en-GB': 'Stock settings saved',
        },
    },
    adminProductDetailsStockError: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProduct,
        tags: ['static', 'admin', 'product', 'error'],
        translations: {
            'de-DE': 'Fehler beim Speichern der Bestandseinstellungen',
            'en-GB': 'Error saving stock settings',
        },
    },
};
