import React, { Fragment, PropsWithChildren, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { IconButton } from '../controls/button/IconButton';
import { useTranslationApply } from '../Translations/hooks/useTranslationApply';
import { useTranslation } from '../Translations/hooks/useTranslation';

interface IModal extends PropsWithChildren {
    show: boolean;
    title?: string;
    titleTranslationKey?: string;
    afterClose: () => void;
    closeable?: boolean;
    background?: 'white' | 'neutral';
    maxWidthPreset?:
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | '2xl'
        | '3xl'
        | '4xl'
        | '5xl'
        | '6xl'
        | '7xl'
        | 'full'
        | undefined;
}

export const Modal: React.FC<IModal> = (props) => {
    const {
        children,
        show,
        title = '',
        titleTranslationKey,
        afterClose,
        closeable = false,
        maxWidthPreset = '2xl',
        background = 'white',
    } = props;

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const modalWidth = useMemo(() => {
        if (maxWidthPreset) {
            return `max-w-${maxWidthPreset}`;
        }
        return `max-w-5xl`;
    }, [maxWidthPreset]);

    const bgStyles: string = useMemo(() => {
        if (background === 'neutral') {
            return 'bg-neutral-100';
        }

        return 'bg-white';
    }, [background]);

    return (
        <>
            {show && (
                <>
                    <div className="fixed top-0 left-0 z-50 flex h-screen w-screen bg-black/70 shadow-xl backdrop-blur-sm"></div>
                </>
            )}

            <Transition appear show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 h-full w-full overflow-y-auto"
                    onClose={() => afterClose()}
                >
                    <div className="relative flex h-screen max-h-screen w-screen items-center justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className={`relative flex h-max max-h-[90vH] w-full transform flex-col rounded p-1 shadow-xl transition-all ${modalWidth} ${bgStyles}`}
                            >
                                <section className="relative flex w-full flex-row gap-1">
                                    <h2 className="flex w-full grow items-center justify-center text-lg font-medium">
                                        <span
                                            {...applyTranslation({
                                                key: titleTranslationKey,
                                            })}
                                        >
                                            {titleTranslationKey && getT(titleTranslationKey)
                                                ? getT(titleTranslationKey)
                                                : title}
                                        </span>
                                    </h2>

                                    {closeable && (
                                        <IconButton
                                            wrapperCss="shrink"
                                            onClick={() => afterClose()}
                                        >
                                            <XMarkIcon />
                                        </IconButton>
                                    )}
                                </section>

                                <div className="relative flex max-h-screen w-full flex-col overflow-y-auto overflow-x-hidden p-2 text-left align-middle">
                                    {children}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
