import { useTranslationState } from '../state/translation.state';
import { useCallback } from 'react';
import { useLocalisationState } from '../state/localisation.state';
import { ICategory } from '../../../../interfaces/category/ICategory';
import { ITranslations } from '../../../../interfaces/translation/translations.interfaces';
import { useTranslationGetContent } from './useTranslationGetContent';

export const useTranslation = () => {
    const { getTranslationByLocale, setTranslationKey } = useTranslationState();
    const { userCurrentLanguage, tenantDefaultLanguage } = useLocalisationState();
    const { getTranslationContent } = useTranslationGetContent();
    const currentLocale = userCurrentLanguage?.languageCode ?? 'de-DE';

    const setT = useCallback(
        (key: string, locale: string, value: string) => {
            setTranslationKey(key, locale, value);
        },
        [setTranslationKey]
    );

    const getT = useCallback(
        (key: string, locale?: string): string | null => {
            return getTranslationByLocale(
                key,
                locale ?? currentLocale,
                tenantDefaultLanguage?.languageCode
            );
        },
        [getTranslationByLocale, currentLocale, tenantDefaultLanguage]
    );

    const getTranslatedCategoryName = useCallback(
        (category: ICategory): string => {
            if (!category?.nameTranslation) {
                return category?.name || category?.label || '';
            }

            // Check if there's a translation for the current language
            const translationEntry = category.nameTranslation.translations?.find(
                (translation) => translation.languageCode === currentLocale
            );

            if (translationEntry) {
                return translationEntry.translation;
            }

            // If no translation found for current language, return the base translation
            return (
                category.nameTranslation.baseTranslation || category.name || category.label || ''
            );
        },
        [currentLocale]
    );

    // TODO pass a ITranslations object and get the current translation based on user language
    const getTranslatedString = useCallback(
        (translation: ITranslations | undefined): string | null => {
            if (!translation) {
                return null;
            }

            // Check if there's a translation for the current language
            const translationEntry = translation.translations?.find(
                (t) => t.languageCode === currentLocale
            );

            if (translationEntry) {
                return translationEntry.translation;
            }

            // If no translation found for current language, return the base translation
            return translation.baseTranslation || null;
        },
        [currentLocale]
    );
    const getTranslationByTranslationsConstruct = useCallback(
        (translation: ITranslations) => {
            if (translation) {
                return getTranslationContent(translation, translation.baseTranslation ?? '');
            }

            return '';
        },
        [getTranslationContent]
    );

    return {
        getT,
        setT,
        getTranslatedCategoryName,
        getTranslationByTranslationsConstruct,
        getTranslatedString,
    };
};
