import { ETranslationGroups } from './translation.enums';
import { profileTranslation } from './profile.translation';
import { billingAddressTranslation } from './billingAddress.translation';
import { navigationTranslations } from './navigation.translation';
import { cartTranslation } from './cart.translation';
import { orderAddressTranslation } from './orderAddress.translation';
import { headerTranslation } from './header.translation';
import { adminThemeTranslation } from './adminTheme.translation';
import { footerTranslations } from './footer.translation';
import { productTranslations } from './product.translation';
import { checkoutTranslations } from './checkout.translation';
import { toastTranslations } from './toast.translation';
import { confirmTranslations } from './confirm.translation';
import { pageCMSTranslation } from './cmsPage.translation';
import { adminTenantTranslation } from './adminTenant.translation';
import { adminProductDetails } from './adminProductDetails.translation';
import { translationsTranslation } from './translations.translation';
import { adminNavigationTranslation } from './adminNavigation.translation';
import { controlsTranslation } from './controls.translation';
import { adminLocalizationTranslation } from './adminLocalization.translation';
import { adminTableTranslation } from './adminTable.translation';
import { adminTranslationsTranslation } from './AdminTranslations.translation';
import { adminUserfieldsTranslations } from './adminUserfields.translation';
import { adminProductfieldsTranslations } from './adminProductfields.translation';
import { adminRolesTranslations } from './adminRoles.translation';
import { adminUsersTranslations } from './adminUsers.translation';
import { adminCategoriesTranslations } from './adminCategories.translation';
import { testTranslation } from './test.translation';
import { searchTranslations } from './search.translation';
import { promptModalTranslation } from './promptModal.translation';

// TODO Group, context
export interface ITranslationMap {
    [key: string]: {
        translations: ITranslationLocales;
        group?: ETranslationGroups;
        context?: string;
        tags?: string[];
        locked?: boolean;
    };
}

export interface ITranslationLocales {
    [key: string]: string;
}

export const translationMapStatic: ITranslationMap = {
    ...profileTranslation,
    ...billingAddressTranslation,
    ...navigationTranslations,
    ...cartTranslation,
    ...orderAddressTranslation,
    ...headerTranslation,
    ...footerTranslations,
    ...productTranslations,
    ...checkoutTranslations,
    ...toastTranslations,
    ...confirmTranslations,
    ...pageCMSTranslation,
    ...adminThemeTranslation,
    ...adminTenantTranslation,
    ...adminProductDetails,
    ...translationsTranslation,
    ...adminNavigationTranslation,
    ...controlsTranslation,
    ...adminLocalizationTranslation,
    ...adminTableTranslation,
    ...adminTranslationsTranslation,
    ...adminUserfieldsTranslations,
    ...adminProductfieldsTranslations,
    ...adminRolesTranslations,
    ...adminUsersTranslations,
    ...adminCategoriesTranslations,
    ...testTranslation,
    ...searchTranslations,
    ...promptModalTranslation,
};
