import { useCallback } from 'react';
import { ETranslationEntity } from '../types/translationApply.types';
import { ETranslationType } from '../enum/translations.enums';
import { translationMapStatic } from '../../../../data/translationMapStatic';

export interface ITranslationApply {
    key?: string | string[];
    type?: ETranslationType;
    entity?: ETranslationEntity;
}

export const useTranslationApply = () => {
    const applyTranslation = useCallback(
        (
            props: ITranslationApply
        ):
            | {
                  'data-translation': string | undefined;
                  'data-translation-type': ETranslationType;
                  'data-translation-entity': ETranslationEntity;
                  'data-translation-locked': string | undefined;
              }
            | undefined => {
            if (!props.key) return undefined;

            let locked = false;
            if (!props.type || props.type === ETranslationType.static) {
                // Find current key in translationMapStatic
                locked = translationMapStatic[props.key.toString()]?.locked ?? false;
            }

            return {
                'data-translation': props.key.toString(),
                'data-translation-type': props.type ?? ETranslationType.static,
                'data-translation-entity': props.entity ?? ETranslationEntity.text,
                'data-translation-locked': locked ? 'true' : undefined,
            };
        },
        []
    );

    return { applyTranslation };
};
