import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const translationsTranslation: ITranslationMap = {
    translationsLabelPlaceholder: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProduct,
        tags: ['static', 'translations', 'placeholder', 'label'],
        translations: {
            'de-DE': 'Übersetzung berabeiten',
            'en-GB': 'Edit translation',
        },
    },

    translationEditorQueuePlaceholder: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProduct,
        tags: ['static', 'translations', 'placeholder', 'queue'],
        translations: {
            'de-DE': 'Wird automatisch übersetzt',
            'en-GB': 'Will be translated automatically',
        },
    },
    translationDefaultTranslationMandatory: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminProduct,
        tags: ['static', 'translations', 'info'],
        translations: {
            'de-DE': 'Bitte füllen Sie die Standardsprache aus, um eine Übersetzung zu erstellen.',
            'en-GB': 'Please fill out the default language to create a translation.',
        },
    },
};
