export const staticFallbackPattern: { [key: string]: string } = {
    // German variants
    'de-CH': 'de-DE',
    'de-AT': 'de-DE',
    'de-LU': 'de-DE',
    'de-BE': 'de-DE',

    // English variants
    'en-US': 'en-GB',
    'en-AU': 'en-GB',
    'en-CA': 'en-GB',
    'en-IE': 'en-GB',
    'en-NZ': 'en-GB',

    // French variants
    'fr-CH': 'fr-FR',
    'fr-BE': 'fr-FR',
    'fr-LU': 'fr-FR',
    'fr-CA': 'fr-FR', // even though French Canada has differences, fallback to standard French

    // Italian variants
    'it-CH': 'it-IT',

    // Spanish variants
    'es-MX': 'es-ES',
    'es-AR': 'es-ES',
    'es-CO': 'es-ES',
    'es-CL': 'es-ES',
    'es-UY': 'es-ES',
    'es-PE': 'es-ES',

    // Portuguese variants
    'pt-BR': 'pt-PT',

    // Dutch variants
    'nl-BE': 'nl-NL',

    // Norwegian variants
    'nb-NO': 'no-NO',
    'nn-NO': 'no-NO',

    // Other Scandinavian
    'sv-FI': 'sv-SE',

    // Romanian variants
    'ro-MD': 'ro-RO',

    // Serbian variants (Latin/Cyrillic)
    'sr-Latn-RS': 'sr-RS',
    'sr-Cyrl-RS': 'sr-RS',
};
