import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const cartTranslation: ITranslationMap = {
    cartCartHeader: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'header'],
        translations: {
            'de-DE': 'Warenkorb',
            'en-GB': 'Cart',
        },
    },
    cartCartHeaderSubline: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'header'],
        translations: {
            'de-DE': 'Hier werden alle Produkte gelistet, die du bestellen möchtest.',
            'en-GB': 'All the products you want to order are listed here.',
        },
    },
    cartCartTab: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'tab'],
        translations: {
            'de-DE': 'Warenkorb',
            'en-GB': 'Cart',
        },
    },

    cartWatchlistHeader: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'header'],
        translations: {
            'de-DE': 'Merkliste',
            'en-GB': 'Watchlist',
        },
    },
    cartWatchlistHeaderSubline: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'header'],
        translations: {
            'de-DE': 'Hier werden alle Produkte gelistet, die du gespeichert hast.',
            'en-GB': 'All products that you have saved are listed here.',
        },
    },
    cartWatchlistTab: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'tab'],
        translations: {
            'de-DE': 'Merkliste',
            'en-GB': 'Watchlist',
        },
    },

    cartDraftsHeader: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'header'],
        translations: {
            'de-DE': 'Entwürfe',
            'en-GB': 'Drafts',
        },
    },
    cartDraftsHeaderSubline: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'header'],
        translations: {
            'de-DE':
                'Hier werden alle deine aktuellen Entwürfe gelistet. Entwürfe sind Produkte, die den Bearbeitungsprozess nie abgeschlossen haben.',
            'en-GB':
                'All your current drafts are listed here. Drafts are products that have never completed the editing process.',
        },
    },
    cartDraftsTab: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'tab'],
        translations: {
            'de-DE': 'Entwürfe',
            'en-GB': 'Drafts',
        },
    },

    cartHistoryHeader: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'header'],
        translations: {
            'de-DE': 'Bestellhistorie',
            'en-GB': 'Order history',
        },
    },
    cartHistoryHeaderSubline: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'header'],
        translations: {
            'de-DE':
                'Hier werden alle Informationen zu getätigten Bestellungen angezeigt. Ebenfalls besteht die Möglichkeit, Produkte erneut zu bestellen oder zu bearbeiten.',
            'en-GB':
                'All information on orders placed is displayed here. It is also possible to reorder or edit products.',
        },
    },
    cartHistoryTab: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'tab'],
        translations: {
            'de-DE': 'Bestellhistorie',
            'en-GB': 'Order history',
        },
    },

    cartNoItemsInfo: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'info'],
        translations: {
            'de-DE': 'Keine Artikel vorhanen',
            'en-GB': 'No items available',
        },
    },

    cartCartTotal: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'summary', 'checkoutTotalLabel'],
        translations: {
            'de-DE': 'Gesamtsumme',
            'en-GB': 'Grand total',
        },
    },

    cartCartSubTotal: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'summary', 'checkoutTotalLabel'],
        translations: {
            'de-DE': 'Zwischensumme',
            'en-GB': 'Subtotal',
        },
    },

    cartTaxInfo: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'info'],
        translations: {
            'de-DE': 'Alle Preise sind Nettopreise zzgl. gesetzlicher MwSt.',
            'en-GB': 'All prices are net prices plus VAT.',
        },
    },

    cartOrderButton: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Warenkorb bestellen',
            'en-GB': 'Order shopping cart',
        },
    },

    cartSearchBasket: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'tip'],
        translations: {
            'de-DE': 'Warenkorb durchsuchen',
            'en-GB': 'Search shopping basket',
        },
    },

    cartSearchItems: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'tip'],
        translations: {
            'de-DE': 'Nach Artikel suchen...',
            'en-GB': 'Search by item...',
        },
    },

    cartSearchOrders: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'tip'],
        translations: {
            'de-DE': 'Bestellungen durchsuchen',
            'en-GB': 'Search orders',
        },
    },

    cartCartTableArticle: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Artikel',
            'en-GB': 'Article',
        },
    },
    cartCartTableModificationDate: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Änderungsdatum',
            'en-GB': 'Change date',
        },
    },
    cartCartTableAmount: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Menge',
            'en-GB': 'Quantity',
        },
    },
    cartCartTablePrice: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Preis',
            'en-GB': 'Price',
        },
    },

    cartCartButtonsActionFor: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Aktion für',
            'en-GB': 'Action for',
        },
    },
    cartCartButtonsArticle: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Artikel',
            'en-GB': 'Article',
        },
    },
    cartCartButtonsToSaved: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Auf die Merkliste',
            'en-GB': 'Add to watchlist',
        },
    },
    cartCartButtonsToCart: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'In den Warenkorb',
            'en-GB': 'Add to cart',
        },
    },
    cartCartButtonsDelete: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Löschen',
            'en-GB': 'Delete',
        },
    },

    cartHistoryTableOrders: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Aufträge',
            'en-GB': 'Orders',
        },
    },
    cartHistoryTableDateOfOrder: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Bestelldatum',
            'en-GB': 'Date of Order',
        },
    },
    cartHistoryTablePrice: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Preis',
            'en-GB': 'Price',
        },
    },
    cartHistoryTableStatus: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Status',
            'en-GB': 'Status',
        },
    },
    cartHistoryTableButtonDetails: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button'],
        translations: {
            'de-DE': 'Einzelheiten',
            'en-GB': 'Details',
        },
    },
    cartCartItemButtonPersonaliseTip: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button', 'tip'],
        translations: {
            'de-DE': 'Personalisieren',
            'en-GB': 'Personalise',
        },
    },
    cartCartItemButtonDuplicateTip: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button', 'tip'],
        translations: {
            'de-DE': 'Artikel duplizieren',
            'en-GB': 'Duplicate article',
        },
    },

    cartCartItemButtonToSavedTip: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button', 'tip'],
        translations: {
            'de-DE': 'Zur Merkliste schieben',
            'en-GB': 'Move to watch list',
        },
    },

    cartCartItemButtonToCartTip: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button', 'tip'],
        translations: {
            'de-DE': 'In den Warenkorb legen',
            'en-GB': 'Add to shopping basket',
        },
    },

    cartCartItemButtonDeleteTip: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button', 'tip'],
        translations: {
            'de-DE': 'Artikel löschen',
            'en-GB': 'Delete article',
        },
    },

    cartCartItemButtonFinaliseTip: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button', 'tip'],
        translations: {
            'de-DE': 'Entwurf abschließen & in den Warenkorb legen',
            'en-GB': 'Finalise design & add to shopping cart',
        },
    },

    cartCartItemButtonRenameTip: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button', 'tip'],
        translations: {
            'de-DE': 'Artikel umbenennen',
            'en-GB': 'Rename article',
        },
    },

    cartCartItemButtonQuantityTip: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table', 'button', 'tip'],
        translations: {
            'de-DE': 'Bestätigen',
            'en-GB': 'Confirm',
        },
    },

    cartCartItemButtonCopy: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Menge & Einstellungen bearbeiten',
            'en-GB': 'Edit quantity & settings',
        },
    },

    cartCartItemButtonRename: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Umbenennen',
            'en-GB': 'Rename',
        },
    },

    cartCartItemModalTitleRenameArticle: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'modal', 'header', 'title'],
        translations: {
            'de-DE': 'Artikel umbenennen',
            'en-GB': 'Rename article',
        },
    },

    cartCartItemStockAvailable: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'verfügbar',
            'en-GB': 'available',
        },
    },

    cartOrderItemsArticle: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Artikel',
            'en-GB': 'Article',
        },
    },
    cartOrderItemsModificationDate: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Änderungsdatum',
            'en-GB': 'Date of modification',
        },
    },
    cartOrderItemsQuantity: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Menge',
            'en-GB': 'Quantity',
        },
    },
    cartOrderItemsPrice: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Preis',
            'en-GB': 'Price',
        },
    },
    cartOrderItemProduct: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Produkt',
            'en-GB': 'Product',
        },
    },
    cartOrderItemReOrder: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'table'],
        translations: {
            'de-DE': 'Erneut Bestellen',
            'en-GB': 'Order again',
        },
    },
    cartOrderItemReOrderJumpToCart: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Zum Warenkorb',
            'en-GB': 'To shopping cart',
        },
    },
    cartOrderItemReOrderJumpToDrafts: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'button'],
        translations: {
            'de-DE': 'Zu den Entwürfen',
            'en-GB': 'To drafts',
        },
    },
    cartOrderAddressHeader: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'address'],
        translations: {
            'de-DE': 'Lieferadresse',
            'en-GB': 'Delivery address',
        },
    },

    cartHistoryStatusInCreation: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'history', 'status'],
        translations: {
            'de-DE': 'In der Schöpfung',
            'en-GB': 'In creation',
        },
    },

    cartHistoryStatusPlaced: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'history', 'status'],
        translations: {
            'de-DE': 'Platziert',
            'en-GB': 'Placed',
        },
    },

    cartHistoryStatusProcessed: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'history', 'status'],
        translations: {
            'de-DE': 'Processed',
            'en-GB': 'Verarbeitet',
        },
    },

    cartHistoryStatusError: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'history', 'status'],
        translations: {
            'de-DE': 'Fehler',
            'en-GB': 'Error',
        },
    },

    cartHistoryStatusFinished: {
        context: undefined,
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'history', 'status'],
        translations: {
            'de-DE': 'Fertig',
            'en-GB': 'Finished',
        },
    },

    cartDeleteItemConfirmText: {
        context: 'Message for user to confirm deletion of an item from the cart.',
        group: ETranslationGroups.cart,
        tags: ['static', 'cart', 'warning'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie den Artikel löschen möchten?',
            'en-GB': 'Are you sure you want to delete this item?',
        },
    },
};
