import { useTranslationState } from '../state/translation.state';
import {
    ITranslationLocales,
    ITranslationMap,
    translationMapStatic,
} from '../../../../data/translationMapStatic';
import { useEffect, useMemo, useRef } from 'react';
import localforage from 'localforage';
import _ from 'lodash';
import { useTranslationsByTenantQuery } from '../../hooks/localisation/useTranslationsByTenantQuery';
import { ETranslationGroups } from '../../../../data/translation.enums';

export const useUpdateTranslationState = () => {
    const staticTranslations = translationMapStatic;

    const { translations, setAllTranslations, lastUpdated, setLastUpdated } = useTranslationState();

    const lastUpdatedAt = useRef(0);

    const { data: translationData, isPending, dataUpdatedAt } = useTranslationsByTenantQuery({});

    const lf = useMemo(() => {
        return localforage.createInstance({
            name: 'cloudbar',
            storeName: 'translations',
            version: 1,
        });
    }, []);

    useEffect(() => {
        if (translationData && lastUpdatedAt.current !== dataUpdatedAt) {
            // console.log('???', translationData, translations);

            const translationNewMapped: ITranslationMap = { ...translations };

            // Map translationData:ITranslations[] to ITranslationLocales
            translationData.forEach((transl) => {
                // console.log('transl: ', transl);

                translationNewMapped[transl.translationKey] = {
                    translations:
                        transl.translations?.reduce((acc, curr) => {
                            acc[curr.languageCode] = curr.translation;
                            return acc;
                        }, {} as ITranslationLocales) ?? {},
                    group: transl.group as ETranslationGroups,
                    context: transl.context,
                    tags: transl.tags,
                };
            });

            // console.log('will update: ', translationNewMapped);

            // Set the translations
            setAllTranslations(translationNewMapped);
            lastUpdatedAt.current = dataUpdatedAt;
        }
    }, [
        dataUpdatedAt,
        isPending,
        setAllTranslations,
        staticTranslations,
        translationData,
        translations,
    ]);

    /**
     * Update the translations if the last update was before the current time
     */
    useEffect(() => {
        const nowUnix = new Date().getTime();

        if (lastUpdated >= nowUnix) {
            return;
        }

        // Start with static translations
        const translationsUpdate: ITranslationMap = { ...staticTranslations };

        // Get translations from indexedDB
        void lf
            .getItem('storedJSON')
            .then((value) => {
                if (value) {
                    const storedParsed = JSON.parse(value as string) as ITranslationMap;

                    // Deep merge the stored translations with the static translations

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    _.merge(translationsUpdate, storedParsed);
                }
            })
            .finally(() => {
                // Skip, when API call was faster then local call.
                if (lastUpdatedAt.current > 0) {
                    return;
                }

                setAllTranslations(translationsUpdate);
            });

        setLastUpdated(nowUnix);
    }, [lastUpdated, lf, setAllTranslations, setLastUpdated, staticTranslations]);
};
