import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const productTranslations: ITranslationMap = {
    productChoose: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static'],
        translations: {
            'de-DE': 'auswählen',
            'en-GB': 'choose',
        },
    },
    productPricelistNotConfigured: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'info'],
        translations: {
            'de-DE': 'Admin-Info: Preisliste nicht konfiguriert.',
            'en-GB': 'Admin-Info: Price list not configured.',
        },
    },

    productProductNotAvailable: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'info'],
        translations: {
            'de-DE': 'Das Produkt ist derzeit nicht bestellbar.',
            'en-GB': 'Product is not available at the moment.',
        },
    },

    productButtonTipEditProductAsAdmin: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button', 'tip'],
        translations: {
            'de-DE': 'Produkt als Administrator bearbeiten',
            'en-GB': 'Edit product as administrator',
        },
    },

    productProduct: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'header'],
        translations: {
            'de-DE': 'Produkt',
            'en-GB': 'Product',
        },
    },

    productButtonTipEditPricetableAsAdmin: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button', 'tip'],
        translations: {
            'de-DE': 'Preistabelle als Administrator bearbeiten',
            'en-GB': 'Edit price table as administrator',
        },
    },

    productSelectLabelQuantity: {
        context: "It's the quantity of a item in an order.",
        group: ETranslationGroups.product,
        tags: ['static', 'select', 'label'],
        translations: {
            'de-DE': 'Auflage',
            'en-GB': 'Quantity',
        },
    },

    productTextfieldLabelEdition: {
        context:
            "It's the quantity of a product like business cards, flyers, etc. It's not 'Edition'.",
        group: ETranslationGroups.product,
        tags: ['static', 'textfield', 'label'],
        translations: {
            'de-DE': 'Auflage',
            'en-GB': 'Quantity',
        },
    },
    productSelectPlaceholderSelectQuantity: {
        context: "It's the quantity of a item in an order, which shall be chosen.",
        group: ETranslationGroups.product,
        tags: ['static', 'select', 'placeholder'],
        translations: {
            'de-DE': 'Auflage auswählen',
            'en-GB': 'Select Quantity',
        },
    },

    productAvailable: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'info'],
        translations: {
            'de-DE': 'Verfügbar',
            'en-GB': 'Available',
        },
    },

    productButtonItemDescriptionSave: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button'],
        translations: {
            'de-DE': 'Speichern',
            'en-GB': 'Save',
        },
    },
    productTextfieldLabelDescription: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'textfield', 'label'],
        translations: {
            'de-DE': 'Eigener Artikelname (Optional)',
            'en-GB': 'Custom item name (optional)',
        },
    },

    productActionButtonSaveAndBackToCart: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button'],
        translations: {
            'de-DE': 'Speichern & zurück',
            'en-GB': 'Save & back',
        },
    },

    productActionToastAddToCartSuccess: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button', 'toast'],
        translations: {
            'de-DE': 'Artikel wurde erfolgreich in den Warenkorb gelegt',
            'en-GB': 'Item has been successfully added to the shopping cart',
        },
    },

    productActionToastAddToCartError: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button', 'toast'],
        translations: {
            'de-DE': 'Artikel konnte nicht in den Warenkorb gelegt werden',
            'en-GB': 'Item could not be added to the shopping cart',
        },
    },

    productActionButtonContinueShopping: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button'],
        translations: {
            'de-DE': 'Weiter einkaufen',
            'en-GB': 'Continue shopping',
        },
    },

    productActionButtonToCart: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button'],
        translations: {
            'de-DE': 'Zum Warenkorb',
            'en-GB': 'To Cart',
        },
    },

    productActionButtonAddToCart: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button'],
        translations: {
            'de-DE': 'Zum Warenkorb hinzufügen',
            'en-GB': 'Add to cart',
        },
    },

    productActionButtonCustomize: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'button'],
        translations: {
            'de-DE': 'Jetzt personalisieren',
            'en-GB': 'Customize',
        },
    },

    productInfoBoxNotAvailableForOrder: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'infobox'],
        translations: {
            'de-DE': 'Das Produkt ist aktuell nicht bestellbar',
            'en-GB': 'The product is currently not available for order',
        },
    },
    productInfoBoxNotPublished: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'infobox'],
        translations: {
            'de-DE': 'Das Produkt ist aktuell nicht veröffentlicht',
            'en-GB': 'The product is currently not published',
        },
    },

    productPricetableTitleForItem: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'pricetable', 'title'],
        translations: {
            'de-DE': 'Preistabelle für den ausgewählten Artikel',
            'en-GB': 'Price table for selected item',
        },
    },

    productPricetableQuantity: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'pricetable'],
        translations: {
            'de-DE': 'Menge',
            'en-GB': 'quantity',
        },
    },

    productPricetablePriceSingleUnit: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'pricetable'],
        translations: {
            'de-DE': 'Preis pro Einheit',
            'en-GB': 'price single unit',
        },
    },

    productPricetableTotal: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'pricetable'],
        translations: {
            'de-DE': 'Gesamt',
            'en-GB': 'total',
        },
    },

    productPricetablePcs: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'pricetable'],
        translations: {
            'de-DE': 'Stk.',
            'en-GB': 'pcs.',
        },
    },

    productTextfieldPlaceholderSearchProducts: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'textfield', 'placeholder'],
        translations: {
            'de-DE': 'Produkte durchsuchen...',
            'en-GB': 'Search products...',
        },
    },

    productFavoriteHeaderFavorites: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'favorite', 'header'],
        translations: {
            'de-DE': 'Favoriten',
            'en-GB': 'Favorites',
        },
    },

    productFavoriteInfoBoxNoneSelected: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'favorite', 'info'],
        translations: {
            'de-DE': 'Derzeit sind keine Favoriten ausgewählt',
            'en-GB': 'There are currently no favorites selected',
        },
    },

    productCatalogItemArrange: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'catalog'],
        translations: {
            'de-DE': 'Anordnen',
            'en-GB': 'Arrange',
        },
    },

    productCatalogItemNotAvailable: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'catalog', 'chip'],
        translations: {
            'de-DE': 'Nicht bestellbar',
            'en-GB': 'Not available to order',
        },
    },

    productCatalogItemOffline: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'catalog', 'chip'],
        translations: {
            'de-DE': 'Offline',
            'en-GB': 'Offline',
        },
    },
    productFormFullScreenMode: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'button'],
        translations: {
            'de-DE': 'Vollbildmodus',
            'en-GB': 'Fullscreen mode',
        },
    },

    productFormEnlargeSidebar: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'button'],
        translations: {
            'de-DE': 'Seitenleiste vergrößern',
            'en-GB': 'Enlarge sidebar',
        },
    },
    productFormReduceSidebar: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'button'],
        translations: {
            'de-DE': 'Seitenleiste verkleinern',
            'en-GB': 'Reduce sidebar',
        },
    },
    productFormUpdatePreview: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'button'],
        translations: {
            'de-DE': 'Vorschau aktualisieren',
            'en-GB': 'Update preview',
        },
    },
    productFormTransferToThumbnails: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'button'],
        translations: {
            'de-DE': 'In Thumbnails übernehmen',
            'en-GB': 'Transfer to thumbnails',
        },
    },
    productFormSaveAsDraft: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'button'],
        translations: {
            'de-DE': 'Als Entwurf speichern',
            'en-GB': 'Save as draft',
        },
    },
    productFormPreview: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'button'],
        translations: {
            'de-DE': 'Vorschau',
            'en-GB': 'Preview',
        },
    },
    productFormAddToCart: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'button'],
        translations: {
            'de-DE': 'In den Warenkorb',
            'en-GB': 'Add to shopping cart',
        },
    },
    productFormModalTitleProductRelase: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'title', 'modal'],
        translations: {
            'de-DE': 'Inhaltliche Freigabe',
            'en-GB': 'Content release',
        },
    },
    productFormRelaseApproveAccuracyWarn: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'info', 'warn'],
        translations: {
            'de-DE':
                'Für die Richtigkeit der Inhalte der von Ihnen bearbeiteten Vorlage sind Sie selbst verantwortlich. Dieses Produkt wird individuell für Sie produziert und unterliegt keiner weiteren inhaltlichen Prüfung. Eine Reklamation aufgrund inhaltlicher Mängel ist ausgeschlossen Zur Prüfung steht Ihnen eine PDF-Vorschau zur Verfügung.',
            'en-GB':
                'You are responsible for the accuracy of the content of the template you have edited. This product is produced individually for you and is not subject to any further content checks. Complaints due to content defects are excluded. A PDF preview is available for you to check.',
        },
    },
    productFormReleaseConfirmMessage: {
        context: undefined,
        group: ETranslationGroups.product,
        tags: ['static', 'form', 'info', 'warn'],
        translations: {
            'de-DE':
                'Bitte bestätigen Sie die inhaltliche Richtigkeit. Erst dann wird der Artikel Ihrer Einkaufsliste hinzugefügt.',
            'en-GB':
                'Please confirm that the content is correct. Only then will the item be added to your shopping list.',
        },
    },
    productChooseVariant: {
        context: 'Short label for the button to choose a variant.',
        group: ETranslationGroups.product,
        tags: ['static', 'variants'],
        translations: {
            'de-DE': 'Wähle eine Variante',
            'en-GB': 'Choose variant',
        },
    },
    // productForm: {
    //     context: undefined,
    //     group: ETranslationGroups.product,
    //     tags: ['static', 'form'],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
