import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const profileTranslation: ITranslationMap = {
    profileHeader: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'header'],
        translations: {
            'de-DE': 'Mein Konto',
            'en-GB': 'My Account',
        },
    },

    profileHeaderSubline: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'header'],
        translations: {
            'de-DE':
                'In Ihrem Konto können Sie persönliche Daten anpassen, Lieferadressen verwalten und Einstellungen für das Rechnungswesen festlegen. Speichern Sie Ihre Änderungen mit nur einem Klick.',
            'en-GB':
                'In your account, you can customise personal data, manage delivery addresses and define settings for invoicing. Save your changes with just one click.specific details.',
        },
    },

    profileMetaDisplayUserName: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'metadisplay'],
        translations: {
            'de-DE': 'Benutzername',
            'en-GB': 'User name',
        },
    },

    profileMetaDisplayPassword: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'metadisplay'],
        translations: {
            'de-DE': 'Passwort',
            'en-GB': 'Password',
        },
    },

    profileChangePasswordLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'metadisplay', 'label'],
        translations: {
            'de-DE': 'Passwort',
            'en-GB': 'Password',
        },
    },

    profileChangePasswordLink: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'metadisplay', 'link'],
        translations: {
            'de-DE': 'Passwort ändern',
            'en-GB': 'Change password',
        },
    },

    profileGeneralInformation: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'headline'],
        translations: {
            'de-DE': 'Allgemeine Angaben',
            'en-GB': 'General Information',
        },
    },
    profileGeneralInformationInfobox: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'infobox'],
        translations: {
            'de-DE':
                'Folgende Angaben können auch in personalisierbaren Vorlagen als Vorbefüllung dienen.',
            'en-GB':
                'The following information can also be used as pre-filling in customisable templates.',
        },
    },

    profileSpecificInformation: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'headline'],
        translations: {
            'de-DE': 'Spezifische Angaben',
            'en-GB': 'Specific information',
        },
    },
    profileSpecificInformationInfobox: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'infobox'],
        translations: {
            'de-DE':
                'Folgende Angaben werden ausschließlich für das Rechnungswesen oder personalisierte Produkte verwendet.',
            'en-GB':
                'The following information is used exclusively for accounting or personalised products.',
        },
    },

    profileSalutationLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Anrede',
            'en-GB': 'Salutation',
        },
    },

    profilePleaseSelectPlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder', 'select'],
        translations: {
            'de-DE': 'Bitte wählen',
            'en-GB': 'Please select',
        },
    },

    profileSalutationSelect: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label', 'select'],
        translations: {
            'de-DE': 'Anrede',
            'en-GB': 'Salutation',
        },
    },

    profileSalutationSelect_Opt_Mr: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label', 'selectOption'],
        translations: {
            'de-DE': 'Herr',
            'en-GB': 'Mr.',
        },
    },
    profileSalutationSelect_Opt_Mrs: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label', 'selectOption'],
        translations: {
            'de-DE': 'Frau',
            'en-GB': 'Mrs.',
        },
    },

    profileSalutationSelect_Opt_Diverse: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label', 'selectOption'],
        translations: {
            'de-DE': 'Divers',
            'en-GB': 'Diverse',
        },
    },

    profileTitleLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Titel',
            'en-GB': 'Title',
        },
    },
    profileTitlePlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': 'Dr. Ing.',
            'en-GB': 'PhD',
        },
    },

    profileFirstnameLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Vorname',
            'en-GB': 'First name',
        },
    },
    profileFirstnamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': 'Max',
            'en-GB': 'John',
        },
    },

    profileSurnameLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Nachname',
            'en-GB': 'Surname',
        },
    },
    profileSurnamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': 'Musterman',
            'en-GB': 'Smith',
        },
    },

    profileCountryLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Land',
            'en-GB': 'Country',
        },
    },

    profilePhoneLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Telefon',
            'en-GB': 'Phone',
        },
    },
    profilePhonePlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': '+49',
            'en-GB': '+44',
        },
    },

    profileFaxLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Fax',
            'en-GB': 'Fax',
        },
    },
    profileFaxPlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': '+49',
            'en-GB': '+44',
        },
    },

    profileMobileLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Mobilnummer',
            'en-GB': 'Mobile number',
        },
    },
    profileMobilePlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': '+49',
            'en-GB': '+44',
        },
    },

    profileStreetandhousenoLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Strasse und Hausnummer',
            'en-GB': 'Street and house number',
        },
    },
    profileStreetandhousenoPlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': 'Musterstrasse 34',
            'en-GB': 'Street 34',
        },
    },

    profileCompanyNamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': '',
            'en-GB': '',
        },
    },

    profileCompanyNameLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Firmenname',
            'en-GB': 'Company name',
        },
    },

    profileDivisionLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Abteilung',
            'en-GB': 'Division',
        },
    },

    profileZipcodePlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': '10117',
            'en-GB': 'N6A2C5',
        },
    },

    profileZipcodeLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'PLZ',
            'en-GB': 'Postalcode',
        },
    },

    profileCityLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Ort',
            'en-GB': 'City',
        },
    },

    profileCityPlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'placeholder'],
        translations: {
            'de-DE': 'Musterstadt',
            'en-GB': 'London',
        },
    },

    profileButtonSaveProfileFields: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'button'],
        translations: {
            'de-DE': 'Profilfelder Speichern',
            'en-GB': 'Save profile fields',
        },
    },
    profileButtonSend: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'button'],
        translations: {
            'de-DE': 'Absenden',
            'en-GB': 'Send',
        },
    },

    profileNewPasswordLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Neues passwort',
            'en-GB': 'New password',
        },
    },

    profileCurrentPasswordLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'label'],
        translations: {
            'de-DE': 'Aktuelles Passwort',
            'en-GB': 'Current password',
        },
    },

    profileSetNewPasswordHeader: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'title', 'modal'],
        translations: {
            'de-DE': 'Neues Passwort festlegen',
            'en-GB': 'Set new password',
        },
    },

    profileForgotPasswordEmailLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'password', 'label'],
        translations: {
            'de-DE': 'E-Mail',
            'en-GB': 'e-mail',
        },
    },
    profileForgotPasswordEmailPlaceholder: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'password', 'placeholder'],
        translations: {
            'de-DE': 'E-Mail eingeben',
            'en-GB': 'Enter e-mail',
        },
    },
    profileForgotPasswordHeader: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'password', 'header', 'title'],
        translations: {
            'de-DE': 'Passwort vergessen?',
            'en-GB': 'Forgot password?',
        },
    },
    profileForgotPasswordButtonSend: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'password', 'button'],
        translations: {
            'de-DE': 'Anweisungen senden',
            'en-GB': 'Send instructions',
        },
    },
    profileForgotPasswordBackTo: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'password'],
        translations: {
            'de-DE': 'Zurück zu',
            'en-GB': 'Back to',
        },
    },
    profileForgotPasswordSignInLink: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'password', 'link'],
        translations: {
            'de-DE': 'Anmelden',
            'en-GB': 'Sign in',
        },
    },
    profileLoginOrForwardWith: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login'],
        translations: {
            'de-DE': 'Oder weiter mit',
            'en-GB': 'or forward with',
        },
    },
    profileLoginTwoFactorAuthentication: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login'],
        translations: {
            'de-DE': 'Zweistufige Authentifizierung',
            'en-GB': 'Two-factor authentication',
        },
    },
    profileLoginLoginToYourAccount: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login'],
        translations: {
            'de-DE': 'In Ihr Konto einloggen',
            'en-GB': 'Log in to your account',
        },
    },
    profileLoginEmailOrUsernameLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login', 'label'],
        translations: {
            'de-DE': 'E-Mail Addresse oder Username',
            'en-GB': 'E-mail address or username',
        },
    },
    profileLoginPasswordLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login', 'label'],
        translations: {
            'de-DE': 'Passwort',
            'en-GB': 'Password',
        },
    },
    profileLoginForgotPasswordLink: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login', 'link'],
        translations: {
            'de-DE': 'Passwort vergessen?',
            'en-GB': 'Forgot password?',
        },
    },
    profileLoginSignInButton: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login', 'button'],
        translations: {
            'de-DE': 'Einloggen',
            'en-GB': 'Sign in',
        },
    },
    profileLogin2FACodeInfo: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login', 'info'],
        translations: {
            'de-DE': 'Den Code finden Sie in der E-Mail, die wir Ihnen zugesendet haben.',
            'en-GB': 'You will find the code in the e-mail we sent you.',
        },
    },
    profileLoginCodeLabel: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login', 'label'],
        translations: {
            'de-DE': 'Code',
            'en-GB': 'Code',
        },
    },
    profileLoginSignInMFACodeButton: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login', 'button'],
        translations: {
            'de-DE': 'Code übermitteln',
            'en-GB': 'Sign in with MFA code',
        },
    },
    profileLoginBackToLoginLink: {
        context: undefined,
        group: ETranslationGroups.profile,
        tags: ['static', 'login', 'link'],
        translations: {
            'de-DE': 'Zurück zum Login',
            'en-GB': 'Back to the login',
        },
    },
    profileCompletionHeadline: {
        context: 'User information a user has to provide',
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'infobox'],
        translations: {
            'de-DE': 'Bitte vervollständigen Sie alle erforderlichen Profilangaben.',
            'en-GB': 'Please complete all required profile information.',
        },
    },
    profileCompletionHeadline100p: {
        context: '',
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'infobox'],
        translations: {
            'de-DE': 'Alle Angaben vervollständigt',
            'en-GB': 'All information completed',
        },
    },
    profileCompletionDescription: {
        context: 'User information a user has to provide',
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'infobox'],
        translations: {
            'de-DE':
                'Um Ihre Bestellung erfolgreich abzuschließen, stellen Sie bitte sicher, dass alle mit einem roten * gekennzeichneten Pflichtfelder korrekt ausgefüllt sind.',
            'en-GB':
                'To successfully complete your order, please ensure that all mandatory fields marked with a red * are correctly filled in.',
        },
    },
    profileCompletionDescription100p: {
        context: '',
        group: ETranslationGroups.profile,
        tags: ['static', 'profile', 'infobox'],
        translations: {
            'de-DE': 'Sie können nun Bestellungen aufgeben und im Warenkorb zur Kasse gehen.',
            'en-GB': 'You can now place orders and proceed to checkout in the shopping cart.',
        },
    },
};
