import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useQuery } from '@tanstack/react-query';
import {
    IGetTranslationsByTenantInput,
    ITranslations,
} from '../../../../interfaces/translation/translations.interfaces';
import { useRouter } from 'next/router';

export const useTranslationsByTenantQuery = (input?: IGetTranslationsByTenantInput) => {
    const { postGql } = useApi();

    // TODO Remove after CLOUDBAR-233 translationsByTenant public is done.
    const { isReady, pathname } = useRouter();

    return useQuery({
        // TODO remove enabled when CLOUDBAR-233 translationsByTenant public is done.
        enabled: isReady && !pathname.startsWith('/login'),
        queryKey: ['translationsByTenant'],
        queryFn: async () => {
            const { translationsByTenant: data } = await postGql<{
                translationsByTenant: ITranslations[];
            }>(
                'translationsByTenant',
                gql`
                    query ($input: GetTranslationsByTenantInput!) {
                        translationsByTenant(input: $input) {
                            translationKey
                            baseTranslation
                            languageCode
                            group
                            context
                            tags
                            translations {
                                languageCode
                                translation
                            }
                        }
                    }
                `,
                { input: input }
            );

            return data;
        },
    });
};
