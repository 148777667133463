export const getRedirectUrl = (): string => {
    const path = window.location.pathname + window.location.search;

    if (path.includes('redirect=')) {
        return path;
    }
    return path !== '/'
        ? `/login?logout=1&deepLink=${encodeURIComponent(Buffer.from(path).toString('base64'))}`
        : '/login?logout=1';
};

export const getDecodedDeepLink = (deepLink: string | null): string => {
    if (!deepLink) {
        return '/';
    }
    
    try {
        return Buffer.from(decodeURIComponent(deepLink), 'base64').toString();
    } catch (e) {
        return '/';
    }
};
