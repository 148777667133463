import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import type { AppProps } from 'next/app';
import { Bounce, ToastContainer } from 'react-toastify';
import React, { useState } from 'react';

import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BLFormContextProvider } from 'src/components/frontend/elements/BLForms/context/BLFormContext';
import { BLFormContextSettingsProvider } from 'src/components/frontend/elements/BLForms/context/BLFormContextSettings';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SWUpdater } from '../src/components/general/elements/SWUpdater/SWUpdater';
import { TranslationsUpdater } from '../src/components/general/Translations/elements/TranslationsUpdater';
import { ConfirmPrompt } from '../src/components/general/elements/confirmPrompt/ConfirmPrompt';

const MyApp = ({ Component, pageProps }: AppProps): unknown => {
    const [queryClient] = useState(
        new QueryClient({
            defaultOptions: {
                queries: {
                    // cacheTime: 60 * 5, // 24 hours
                },
            },
        })
    );

    return (
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <BLFormContextProvider>
                    <BLFormContextSettingsProvider>
                        <Component {...pageProps} />
                        <SWUpdater />
                        <ToastContainer
                            position="top-left"
                            autoClose={2000}
                            hideProgressBar
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                            transition={Bounce}
                        />
                        <TranslationsUpdater />
                        <ConfirmPrompt />
                    </BLFormContextSettingsProvider>
                </BLFormContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </RecoilRoot>
    );
};

export default MyApp;
