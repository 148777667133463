import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminThemeTranslation: ITranslationMap = {
    adminThemeBorderSelect_Opt_Strong: {
        context: undefined,
        group: ETranslationGroups.theme,
        tags: ['static', 'admin', 'selectOption'],
        translations: {
            'de-DE': 'Stark',
            'en-GB': 'Strong',
        },
    },
    adminThemeBorderSelect_Opt_Light: {
        context: undefined,
        group: ETranslationGroups.theme,
        tags: ['static', 'admin', 'selectOption'],
        translations: {
            'de-DE': 'Leicht',
            'en-GB': 'Light',
        },
    },
    adminThemeBorderSelect_Opt_None: {
        context: undefined,
        group: ETranslationGroups.theme,
        tags: ['static', 'admin', 'selectOption'],
        translations: {
            'de-DE': 'Keine Rundung',
            'en-GB': 'No rounding',
        },
    },
    adminThemeBorderSelectLabel: {
        context: undefined,
        group: ETranslationGroups.theme,
        tags: ['static', 'admin', 'select', 'label'],
        translations: {
            'de-DE': 'Rahmenrundungen',
            'en-GB': 'Border rounding',
        },
    },
};
