import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const testTranslation: ITranslationMap = {
    testKey: {
        context: undefined,
        group: ETranslationGroups.test,
        tags: ['test', 'tdd'],
        translations: {
            'de-DE': 'Testschlüssel',
            'en-GB': 'Testkey',
        },
    },
    testKeyWithOutENTranslation: {
        context: undefined,
        group: ETranslationGroups.test,
        tags: ['test', 'tdd'],
        translations: {
            'de-DE': 'Testschlüssel',
        },
    },
    testKeyEmpty: {
        context: undefined,
        group: ETranslationGroups.test,
        tags: ['test', 'tdd'],
        translations: {
            'de-DE': '',
            'en-GB': '',
        },
    },
};
