import React, {
    CSSProperties,
    PropsWithChildren,
    ReactElement,
    useCallback,
    useMemo,
    useState,
} from 'react';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../theme/ComputedStyles/recoil/computedStyles.recoil';
import Ripples from 'react-ripples';
import { useThemeStyles } from '../../theme/TenantTheme/hooks/useThemeStyles';
import { useAdminControlsState } from '../../elements/adminControls/state/adminControls.state';
import { useTranslationApply } from '../../Translations/hooks/useTranslationApply';
import { useTranslation } from '../../Translations/hooks/useTranslation';
import { ETranslationType } from '../../Translations/enum/translations.enums';
import { ETranslationEntity } from '../../Translations/types/translationApply.types';

export interface IButton extends PropsWithChildren {
    onClick?: () => void;
    href?: string;
    target?: string;
    additionalCss?: string;
    wrapperCss?: string;
    color?:
        | 'primary'
        | 'primary-light'
        | 'secondary'
        | 'danger'
        | 'success'
        | 'disabled'
        | 'neutral'
        | 'info'
        | 'cloudbar'
        | 'admin-primary';

    iconEnd?: ReactElement<unknown>;
    iconStart?: ReactElement<unknown>;
    iconSpin?: boolean;
    disabled?: boolean;
    alignWithFormFields?: boolean;
    longBtn?: boolean;
    testId?: string;
    iconStartOpen?: ReactElement<unknown>;
    iconEndOpen?: ReactElement<unknown>;
    isResponsive?: boolean;
    responsiveShrink?: boolean;
    attentionIndicator?: boolean;
    enforceWhenLocationsControls?: boolean;
    isTranslatable?: boolean;
    translationKey?: string;
}

export const Button: React.FC<IButton> = (props) => {
    const {
        children,
        additionalCss,
        wrapperCss,
        onClick,
        color = 'primary',
        iconEnd,
        iconStart,
        href,
        target = '_self',
        disabled = false,
        iconSpin = false,
        testId,
        alignWithFormFields = false,
        iconEndOpen,
        iconStartOpen,
        isResponsive,
        responsiveShrink,
        attentionIndicator = false,
        enforceWhenLocationsControls = false,
        isTranslatable = true,
        translationKey,
    } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const { languageControls } = useAdminControlsState();

    const [hovering, setHovering] = useState(false);

    const { applyStyles } = useThemeStyles();

    const handleClick = useCallback(() => {
        // Will skip the onClick when localisation Mode is active for the admin
        if (languageControls && !enforceWhenLocationsControls && isTranslatable) {
            return;
        }

        if (onClick) {
            onClick();
        }
    }, [enforceWhenLocationsControls, isTranslatable, languageControls, onClick]);

    const buttonStyles = useMemo((): CSSProperties | undefined => {
        const borderStyles = applyStyles({
            rounded: tenantTheme.border?.radius,
        });

        // Define the final styles
        switch (color) {
            case 'cloudbar':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.cloudbar.light',
                            textColor: 'colors.cloudbar.white',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    ...applyStyles({
                        bgColor: 'colors.cloudbar.DEFAULT',
                        textColor: 'colors.cloudbar.white',
                    }),
                    ...borderStyles,
                };
            case 'primary':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.background.primaryLight',
                            textColor: 'colors.invert.primaryLight',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    ...applyStyles({
                        bgColor: 'colors.background.primary',
                        textColor: 'colors.invert.primary',
                    }),
                    ...borderStyles,
                };

            case 'primary-light':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.background.primaryLightHover',
                            textColor: 'colors.invert.primaryLight',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    ...applyStyles({
                        bgColor: 'colors.background.primaryLight',
                        textColor: 'colors.invert.primaryLight',
                    }),
                    ...borderStyles,
                };

            case 'secondary':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.background.secondaryHover',
                            textColor: 'colors.invert.secondary',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    ...applyStyles({
                        bgColor: 'colors.background.secondary',
                        textColor: 'colors.invert.secondary',
                    }),
                    ...borderStyles,
                };

            case 'danger':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.background.dangerHover',
                            textColor: 'colors.invert.danger',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    ...applyStyles({
                        bgColor: 'colors.background.danger',
                        textColor: 'colors.invert.danger',
                    }),
                    ...borderStyles,
                };

            case 'success':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.background.successHover',
                            textColor: 'colors.invert.success',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    ...applyStyles({
                        bgColor: 'colors.background.success',
                        textColor: 'colors.invert.success',
                    }),
                    ...borderStyles,
                };

            case 'neutral':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.background.neutralHover',
                            textColor: 'colors.invert.neutral',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    backgroundColor: tenantTheme.colors.background.neutral,
                    color: tenantTheme.colors.invert.neutral,
                    ...borderStyles,
                };

            case 'disabled':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.background.disabledHover',
                            textColor: 'colors.invert.disabled',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    ...applyStyles({
                        bgColor: 'colors.background.disabled',
                        textColor: 'colors.invert.disabled',
                    }),
                    ...borderStyles,
                };

            case 'info':
                if (hovering) {
                    return {
                        ...applyStyles({
                            bgColor: 'colors.background.infoHover',
                            textColor: 'colors.invert.info',
                        }),
                        ...borderStyles,
                    };
                }

                return {
                    ...applyStyles({
                        bgColor: 'colors.background.info',
                        textColor: 'colors.invert.info',
                    }),
                    ...borderStyles,
                };
        }
    }, [color, hovering, tenantTheme, applyStyles]);

    const iconSpinStyle = useMemo(() => {
        return iconSpin ? 'animate-spin' : '';
    }, [iconSpin]);

    const responsiveShrinkStyles = useMemo((): string => {
        if (responsiveShrink) return 'hidden lg:inline-flex';
        return 'inline';
    }, [responsiveShrink]);

    const buttonBody = (
        <>
            {(iconStart || iconStartOpen) && (
                <span className={`w-4 ${iconSpinStyle}`}>{iconStart ?? iconStartOpen}</span>
            )}
            <span
                data-test-id={`${testId ?? ''}-label`}
                className={`grow truncate text-ellipsis whitespace-nowrap pointer-events-none ${responsiveShrinkStyles} ${
                    isResponsive
                        ? 'text-xs xs:text-sm sm:text-base md:text-lg lg:text-sm'
                        : 'text-sm'
                }`}
            >
                {translationKey ? getT(translationKey) ?? '' : children}
            </span>
            {(iconEnd || iconEndOpen) && (
                <span className={`w-4 ${iconSpinStyle}`}>{iconEnd ?? iconEndOpen}</span>
            )}
        </>
    );

    const className = useMemo(() => {
        const baseCss =
            'relative flex w-full flex-row items-center gap-2 rounded font-semibold tracking-wide';
        const additionalCssOption = additionalCss ?? '';
        const cursorOption = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
        const iconOption = iconStart || iconEnd ? 'justify-between' : 'justify-center';

        return `${baseCss} ${additionalCssOption} ${cursorOption} ${iconOption} h-10 px-4 py-3`;
    }, [additionalCss, disabled, iconStart, iconEnd]);

    const rippleAlignWithFields = useMemo(() => {
        if (alignWithFormFields) {
            return 'mt-[18px] h-[40px]';
        }

        return 'h-max';
    }, [alignWithFormFields]);

    const hrefSanitized = useMemo(() => {
        if (!href) {
            return undefined;
        }

        if (languageControls && !enforceWhenLocationsControls) {
            return '#';
        }

        return href;
    }, [enforceWhenLocationsControls, href, languageControls]);

    const outlineClassesTranslation: string = useMemo(() => {
        if (!isTranslatable) return '';

        if (!languageControls) return '';

        return 'outline outline-offset-2 outline-pink-500 rounded';
    }, [isTranslatable, languageControls]);

    return (
        <Ripples
            color={disabled ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.2)'}
            during={500}
            className={`relative ${
                wrapperCss ?? 'w-max'
            } ${rippleAlignWithFields} overflow-hidden ${outlineClassesTranslation}`}
            placeholder={undefined}
            {...({} as Pick<
                React.HTMLAttributes<HTMLDivElement>,
                // @ts-ignore
                'onPointerEnterCapture' | 'onPointerLeaveCapture'
            >)}
        >
            <div className="relative flex w-full flex-col gap-0">
                {alignWithFormFields && <div className="relative block h-[26px]" />}

                {onClick && !hrefSanitized && (
                    <button
                        className={`button ${className}`}
                        onClick={() => {
                            setTimeout(() => {
                                handleClick();
                            }, 150);
                        }}
                        role="button"
                        disabled={disabled}
                        aria-disabled={disabled}
                        data-test-id={`${testId ?? ''}-button`}
                        style={{ ...buttonStyles }}
                        onMouseEnter={() => {
                            setHovering(true);
                        }}
                        onMouseLeave={() => setHovering(false)}
                        {...(translationKey && isTranslatable
                            ? applyTranslation({
                                  key: translationKey,
                                  type: ETranslationType.static,
                                  entity: ETranslationEntity.text,
                              })
                            : {})}
                    >
                        {buttonBody}
                    </button>
                )}

                {hrefSanitized && (
                    <Link
                        href={hrefSanitized}
                        onClick={handleClick}
                        target={target}
                        className={`button ${className}`}
                        style={{ ...buttonStyles }}
                        data-test-id={`${testId ?? ''}-button`}
                        onMouseEnter={() => {
                            setHovering(true);
                        }}
                        onMouseLeave={() => setHovering(false)}
                        {...(translationKey && isTranslatable
                            ? applyTranslation({
                                  key: translationKey,
                                  type: ETranslationType.static,
                                  entity: ETranslationEntity.text,
                              })
                            : {})}
                    >
                        {buttonBody}
                    </Link>
                )}

                {attentionIndicator && (
                    <div
                        className="absolute top-0 right-0"
                        data-test-id={`${testId ?? ''}-button-attention-indicator`}
                    >
                        <figure className="h-16 w-16 animate-ping rounded-full bg-white/50" />
                    </div>
                )}
            </div>
        </Ripples>
    );
};
