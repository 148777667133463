import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const checkoutTranslations: ITranslationMap = {
    checkoutHeaderCheckout: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'header'],
        translations: {
            'de-DE': 'Kasse',
            'en-GB': 'Checkout',
        },
    },
    checkoutHeaderOrderSummary: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'header'],
        translations: {
            'de-DE': 'Zusammenfassung der Bestellung',
            'en-GB': 'Order summary',
        },
    },
    checkoutPlaceOrderToggleAcceptTerms: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'toggle', 'link', 'label'],
        translations: {
            'de-DE': 'Allgemeine Geschäftsbedingungen akzeptieren',
            'en-GB': 'Accept general terms and conditions',
        },
    },

    checkoutPlaceOrderToggleAccept: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'toggle', 'link', 'label'],
        translations: {
            'de-DE': 'Akzeptieren',
            'en-GB': 'Accept',
        },
    },

    checkoutButtonContinue: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'button'],
        translations: {
            'de-DE': 'Weiter einkaufen',
            'en-GB': 'Continue shopping',
        },
    },

    checkoutPlaceOrderButtonPlaceOrder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout'],
        translations: {
            'de-DE': 'Bestellung aufgeben',
            'en-GB': 'Place an order',
        },
    },

    // #### Shipping Address form
    checkoutShippingHeadline: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'headline'],
        translations: {
            'de-DE': 'Lieferadresse',
            'en-GB': 'Shipping address',
        },
    },
    checkoutShippingInfobox: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'infobox'],
        translations: {
            'de-DE': 'Die folgenden Informationen werden für Versandzwecke verwendet.',
            'en-GB': 'The following information is used for shipping purposes.',
        },
    },

    checkoutShippingSalutationLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Anrede',
            'en-GB': 'Salutation',
        },
    },

    checkoutShippingCountryLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Land',
            'en-GB': 'Country',
        },
    },

    checkoutShippingTitleLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Titel',
            'en-GB': 'Title',
        },
    },
    checkoutShippingTitlePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'shipping', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Dr. Ing.',
            'en-GB': 'PhD',
        },
    },

    checkoutShippingFirstnameLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Vorname',
            'en-GB': 'First name',
        },
    },
    checkoutShippingFirstnamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Max',
            'en-GB': 'John',
        },
    },

    checkoutShippingSurnameLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Nachname',
            'en-GB': 'Surname',
        },
    },
    checkoutShippingSurnamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Musterman',
            'en-GB': 'Smith',
        },
    },

    checkoutShippingCompanynameLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Firmenname',
            'en-GB': 'Company name',
        },
    },
    checkoutShippingCompanynamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Firmenname GmbH',
            'en-GB': 'Company name Ltd.',
        },
    },

    checkoutShippingPhoneLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Telefon',
            'en-GB': 'Phone',
        },
    },
    checkoutShippingPhonePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'placeholder'],
        translations: {
            'de-DE': '+49 1234 567 890',
            'en-GB': '+44 1234 567 890',
        },
    },

    checkoutShippingStreetandhousenoLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Strasse und Hausnummer',
            'en-GB': 'Street and house number',
        },
    },
    checkoutShippingStreetandhousenoPlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Musterstrasse 34',
            'en-GB': 'Street 34',
        },
    },

    checkoutShippingZipcodeLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'PLZ',
            'en-GB': 'Zip code',
        },
    },
    checkoutShippingZipcodePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'placeholder'],
        translations: {
            'de-DE': '10117',
            'en-GB': 'N6A2C5',
        },
    },

    checkoutShippingCityLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'label'],
        translations: {
            'de-DE': 'Ort',
            'en-GB': 'City',
        },
    },
    checkoutShippingCityPlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'shipping', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Musterstadt',
            'en-GB': 'London',
        },
    },
    // #### END of Shipping Address form

    // @@@@ Billing Address form
    checkoutBillingHeadline: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'headline'],
        translations: {
            'de-DE': 'Rechnungsadresse',
            'en-GB': 'Billing address',
        },
    },
    checkoutBillingInfobox: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'infobox'],
        translations: {
            'de-DE': 'Folgende Angaben werden ausschließlich für das Rechnungswesen verwendet.',
            'en-GB': 'The following information is used exclusively for accounting purposes.',
        },
    },

    checkoutBillingSalutationLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Anrede',
            'en-GB': 'Salutation',
        },
    },

    checkoutBillingCountryLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Land',
            'en-GB': 'Country',
        },
    },

    checkoutBillingTitleLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Titel',
            'en-GB': 'Title',
        },
    },
    checkoutBillingTitlePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Dr. Ing.',
            'en-GB': 'PhD',
        },
    },

    checkoutBillingFirstnameLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Vorname',
            'en-GB': 'First name',
        },
    },
    checkoutBillingFirstnamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Max',
            'en-GB': 'John',
        },
    },

    checkoutBillingSurnameLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Nachname',
            'en-GB': 'Surname',
        },
    },
    checkoutBillingSurnamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Musterman',
            'en-GB': 'Smith',
        },
    },

    checkoutBillingCompanynameLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Firmenname',
            'en-GB': 'Company name',
        },
    },
    checkoutBillingCompanynamePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Firmenname GmbH',
            'en-GB': 'Company name Ltd.',
        },
    },

    checkoutBillingPhoneLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Telefon',
            'en-GB': 'Phone',
        },
    },
    checkoutBillingPhonePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': '+49 1234 567 890',
            'en-GB': '+44 1234 567 890',
        },
    },

    checkoutBillingEmailLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Email',
            'en-GB': 'e-mail',
        },
    },
    checkoutBillingEmailPlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'muster@muster.de',
            'en-GB': 'john@email.uk',
        },
    },

    checkoutBillingEmailccLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'CC',
            'en-GB': 'Recipient e-mail',
        },
    },
    checkoutBillingEmailccPlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'muster@cc-email.de',
            'en-GB': 'recipient@email.uk',
        },
    },

    checkoutBillingStreetandhousenoLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Strasse und Hausnummer',
            'en-GB': 'Street and house number',
        },
    },
    checkoutBillingStreetandhousenoPlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Musterstrasse 34',
            'en-GB': 'Street 34',
        },
    },

    checkoutBillingZipcodeLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'PLZ',
            'en-GB': 'Zip code',
        },
    },
    checkoutBillingZipcodePlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': '10117',
            'en-GB': 'N6A2C5',
        },
    },

    checkoutBillingCityLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Ort',
            'en-GB': 'City',
        },
    },
    checkoutBillingCityPlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Musterstadt',
            'en-GB': 'London',
        },
    },

    checkoutBillingCostCenterLabel: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'label'],
        translations: {
            'de-DE': 'Kostenstelle',
            'en-GB': 'Cost center',
        },
    },
    checkoutBillingCostCenterPlaceholder: {
        context: undefined,
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'billing', 'address', 'placeholder'],
        translations: {
            'de-DE': 'Kostenstelle',
            'en-GB': 'Cost center',
        },
    },
    checkoutSAPAribaInfoBox: {
        context: 'Punch-Out Return to SAP Business Network',
        group: ETranslationGroups.checkout,
        tags: ['static', 'checkout', 'ariba', 'infobox', 'ariba', 'SAP'],
        translations: {
            'de-DE':
                'Durch das Absenden wird Ihre Bestellung an das SAP Business Network zurückübermittelt, und Sie werden anschließend weitergeleitet.',
            'en-GB':
                'By submitting, your order will be returned to the SAP Business Network and you will be redirected.',
        },
    },
};
