import createStateContext from 'react-use/lib/factory/createStateContext';
import { IBLVaultGlobal } from '../BLVault';
import { ICustomData } from '../BLInterfaces';
import { ISurveyFormShowWhen } from '../interfaces/FormInterfaces';

export type TFormUserFields = {
    [key: string]: string;
} | null;

export const defaultFieldContext = {
    id: 'default',
    vault: {
        global: {},
        custom: {},
        meta: {
            progress: 0,
            ptrFinal: [],
        },
    },
    page: 1,
    pages: 1,
    preflightDone: false,
    customData: {},
    elementsOrder: [],
    showWhen: {},
    hideWhen: {},
    optionalWhen: {},
    initialized: false,
    requiredElements: [],
    allElements: [],
    showRequiredHighlights: false,
    userFields: null,
};

// TODO OBSOLETE
/**
 * name and value types of all fields used in form filling
 */
export interface IFormContext {
    id: string;
    vault: IBLVaultGlobal;
    page: number;
    pages: number;
    preflightDone: boolean;
    customData: ICustomData;
    elementsOrder: string[];
    showWhen: ISurveyFormShowWhen;
    hideWhen: ISurveyFormShowWhen;
    optionalWhen: ISurveyFormShowWhen;
    initialized?: boolean;
    requiredElements: string[];
    allElements: string[];
    showRequiredHighlights: boolean;
    userFields: TFormUserFields;
}

export const DefaultFieldsValues: IFormContext = defaultFieldContext;

/**
 * Main FormFilling context.
 * Creates a shared state context that you can use with `useField` hook to R/W to individual fields
 */
export const [useBLFormContext, BLFormContextProvider] =
    createStateContext<IFormContext>(DefaultFieldsValues);
