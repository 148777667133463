import React, { useCallback, useEffect, useState } from 'react';
import { Workbox } from 'workbox-window';
import { Button } from '../../controls/button/Button';
import { Transition } from '@headlessui/react';
import { ArrowPathIcon } from '@heroicons/react/16/solid';
import Router from 'next/router';

export const SWUpdater: React.FC = () => {
    const [swWaitsForUpdate, setSwWaitsForUpdate] = useState(false);

    const [newSwTookOver, setNewSwTookOver] = useState(false);

    const [loading, setLoading] = useState(false);

    const [sw, setSw] = useState<Workbox | null>(null);

    const reloadAndUpdate = useCallback(
        (url?: string) => {
            // Must the singelton instance of sw in state
            if ('serviceWorker' in window.navigator && sw) {
                sw?.addEventListener('controlling', (e) => {
                    // eslint-disable-next-line no-console
                    console.log(e, 'Controlling!');

                    sw?.messageSkipWaiting();

                    if (url) {
                        // console.log('>>>, url', url);
                        window.location.href = url;
                    } else {
                        // console.log('RELOAD 11');
                        window.location.reload();
                    }
                });

                // eslint-disable-next-line no-console
                console.log('messageSkipWaiting');
                // wb.messageSkipWaiting();

                sw?.messageSkipWaiting();
            }
        },
        [sw]
    );

    useEffect(() => {
        const preventRouting = () => {
            return !newSwTookOver;
        };

        const handleRouteChange = (url: string) => {
            if (newSwTookOver) {
                // console.log('!!!! 301', url);
                // console.log('GO!');
                window.location.href = url;
                return;
            }
        };

        Router.beforePopState(preventRouting);

        Router.events.on('routeChangeStart', handleRouteChange);

        // Cleanup
        return () => {
            Router.events.off('routeChangeStart', handleRouteChange);
            Router.beforePopState(() => true);
        };
    }, [newSwTookOver, reloadAndUpdate, sw]);

    useEffect(() => {
        if ('serviceWorker' in window.navigator) {
            const wb = new Workbox('/service-worker.js');

            setSw(wb);

            /**
             * Inform the user that a new version is available
             */
            wb.addEventListener('activated', (e) => {
                if (e.isUpdate) {
                    // eslint-disable-next-line no-console
                    console.log('Serviceworker successfully updated!');
                } else {
                    // eslint-disable-next-line no-console
                    console.log('Serviceworker installed! App is now available offline!');
                }
            });

            /**
             * NEW SW takes over control
             */
            wb.addEventListener('controlling', (e) => {
                // eslint-disable-next-line no-console
                console.log('New SW taking over', e);
                if (e.isUpdate) {
                    // eslint-disable-next-line no-console
                    console.log('Service worker announced an update. Reloading page.');
                    // window.location.reload();
                    setNewSwTookOver(true);
                }
            });

            wb.addEventListener('waiting', () => {
                setSwWaitsForUpdate(true);
            });

            wb.register().catch((e) => {
                console.error('could not register sw', e);
            });
        }
    }, []);

    return (
        <>
            {swWaitsForUpdate && (
                <Transition
                    as={'div'}
                    className="fixed right-2 bottom-2 z-50 h-max w-full rounded border bg-white p-4 px-2 opacity-75 shadow shadow-neutral-500 transition-all group min-h-[100px] max-w-[250px] border-primary hover:opacity-100"
                    show={swWaitsForUpdate ?? false}
                    appear={swWaitsForUpdate ?? false}
                    enter="transition ease-in-out duration-500"
                    enterFrom="transform translate-x-full"
                    enterTo="transform opacity-100 translate-x-0"
                    leave="transition ease-out duration-300"
                    leaveFrom="transform opacity-100 translate-x-0"
                    leaveTo="transform opacity-0 translate-x-full"
                >
                    <div className="relative flex w-full flex-col gap-1 gap-x-1">
                        <span>Eine neue Version steht bereit.</span>
                        <Button
                            disabled={loading}
                            iconSpin={loading}
                            iconStart={<ArrowPathIcon />}
                            onClick={() => {
                                reloadAndUpdate();
                                setLoading(true);
                            }}
                            color={loading ? 'neutral' : 'primary'}
                        >
                            Neu laden
                        </Button>
                    </div>
                </Transition>
            )}
        </>
    );
};
