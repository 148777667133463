export enum ETranslationType {
    static = 'static',
    dynamic = 'dynamic',
    productName = 'productName',
    productDescription = 'productDescription',
    productFieldValue = 'productFieldValue',
    productFieldLabel = 'productFieldLabel',
    productLabelName = 'productLabelName',
    userFieldLabel = 'userFieldLabel',
    categoryName = 'categoryName',
    categoryDescription = 'categoryDescription',
}
