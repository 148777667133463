import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminCategoriesTranslations: ITranslationMap = {
    adminCategoriesModalHeaderTopLevelSorter: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminCategories,
        tags: ['static', 'admin', 'categories', 'header', 'modal'],
        translations: {
            'de-DE': 'Top-Level Kategorien Sortierung',
            'en-GB': 'Top-Level Categories Sorter',
        },
    },

    adminCategoriesSorterButtonLabel: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminCategories,
        tags: ['static', 'admin', 'categories', 'button', 'label'],
        translations: {
            'de-DE': 'Top-Level Kategorien Sortierung',
            'en-GB': 'Top-Level Categories Sorter',
        },
    },

    adminCategoriesSorterModalHelpText: {
        context: 'This is a help text for users',
        locked: true,
        group: ETranslationGroups.adminCategories,
        tags: ['static', 'admin', 'categories', 'description', 'modal'],
        translations: {
            'de-DE':
                'Hier können die Oberkategorieneinträge sortiert werden. Die Sortierung wird direkt gespeichert mit dem Klick auf die Pfeile.',
            'en-GB':
                'Here you can sort the top-level category entries. The sorting is saved directly with the click on the arrows.',
        },
    },

    adminCategoriesSpecialButtonTooltip: {
        context: 'This is a tooltip, keep it short.',
        locked: true,
        group: ETranslationGroups.adminCategories,
        tags: ['static', 'admin', 'categories', 'button', 'tooltip'],
        translations: {
            'de-DE': 'Top-Level Kategoriesortierung bearbeiten',
            'en-GB': 'Edit top-level category sorting',
        },
    },
};
