import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const navigationTranslations: ITranslationMap = {
    navigationHome: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation'],
        translations: {
            'de-DE': 'Startseite',
            'en-GB': 'Home',
        },
    },
    navigationProfile: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu'],
        translations: {
            'de-DE': 'Mein Konto',
            'en-GB': 'My Account',
        },
    },
    navigationCredentials: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu'],
        translations: {
            'de-DE': 'Zugangsdaten verwalten',
            'en-GB': 'Manage access data',
        },
    },
    navigationHistory: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu'],
        translations: {
            'de-DE': 'Bestellhistorie',
            'en-GB': 'Order history',
        },
    },
    navigationForLater: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu'],
        translations: {
            'de-DE': 'Merkliste',
            'en-GB': 'Watchlist',
        },
    },
    navigationAdminPanel: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu'],
        translations: {
            'de-DE': 'Admin Panel',
            'en-GB': 'Admin Panel',
        },
    },
    navigationFAQ: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu', 'FAQ'],
        translations: {
            'de-DE': 'FAQ',
            'en-GB': 'FAQ',
        },
    },
    navigationLogout: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu'],
        translations: {
            'de-DE': 'Ausloggen',
            'en-GB': 'Log out',
        },
    },
    navigationClientApp: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu'],
        translations: {
            'de-DE': 'Client App',
            'en-GB': 'Client App',
        },
    },
    navigationProfileFromAdmin: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'userMenu'],
        translations: {
            'de-DE': 'Profil',
            'en-GB': 'Profile',
        },
    },
    navigationGoToCategory: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation'],
        translations: {
            'de-DE': 'Gehe zur Kategorie',
            'en-GB': 'Go to category',
        },
    },
    navigationBreadcrumbHome: {
        context: undefined,
        group: ETranslationGroups.navigation,
        tags: ['static', 'navigation', 'breadcrumb'],
        translations: {
            'de-DE': 'Startseite',
            'en-GB': 'Home',
        },
    },
};
