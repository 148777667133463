import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminTenantTranslation: ITranslationMap = {
    adminTenantSendGridKeyNote: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.theme,
        tags: ['static', 'admin', 'tenant', 'info'],
        translations: {
            'de-DE':
                'Aus Sicherheitsgründen wird dieser Schlüssel nicht mehr an die UI übergeben. Er ist auf „lesegeschützt“ gesetzt. Sie können jedoch einen neuen Schlüssel eingeben oder den vorhandenen löschen.',
            'en-GB':
                'For security reasons, this key is no longer passed to the UI. It is set to "write-only". However, you can enter a new key or delete the existing one.',
        },
    },
    adminTenantSelectLabelTenant: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.theme,
        tags: ['static', 'admin', 'tenant', 'select', 'label'],
        translations: {
            'de-DE': 'Mandant',
            'en-GB': 'Tenant',
        },
    },
    adminTenantSelectPlaceholderSelectTenant: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.theme,
        tags: ['static', 'admin', 'tenant', 'select', 'placeholder'],
        translations: {
            'de-DE': 'Mandant auswählen',
            'en-GB': 'Select Tenant',
        },
    },
};
