import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminNavigationTranslation: ITranslationMap = {
    adminNavigationDefaultSideBarSettings: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Einstellungen',
            'en-GB': 'Settings',
        },
    },
    adminNavigationDefaultSideBarUserAdministration: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Benutzerverwaltung',
            'en-GB': 'User administration',
        },
    },
    adminNavigationSideBarOverview: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Übersicht',
            'en-GB': 'Overview',
        },
    },
    adminNavigationSideBarDashboard: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Dashboard',
            'en-GB': 'Dashboard',
        },
    },
    adminNavigationSideBarOrders: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Bestellungen',
            'en-GB': 'Orders',
        },
    },
    adminNavigationSideBarProducts: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Produkte',
            'en-GB': 'Products',
        },
    },
    adminNavigationSideBarProductFields: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Produktbeschreibungsfelder',
            'en-GB': 'Product fields',
        },
    },
    adminNavigationSideBarProductMeta: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Produkt Metafelder',
            'en-GB': 'Product meta fields',
        },
    },
    adminNavigationSideBarCategories: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Kategorien',
            'en-GB': 'Categories',
        },
    },
    adminNavigationSideBarPricelists: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Preislisten',
            'en-GB': 'Price lists',
        },
    },
    adminNavigationSideBarCMSpages: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'CMS Seiten',
            'en-GB': 'CMS pages',
        },
    },
    adminNavigationSideBarTenant: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Mandant',
            'en-GB': 'Tenant',
        },
    },
    adminNavigationSideBarLocalization: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Lokalisierung',
            'en-GB': 'Localization',
        },
    },
    adminNavigationSideBarTranslations: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Übersetzungen',
            'en-GB': 'Translations',
        },
    },
    adminNavigationSideBarRoles: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Rollen',
            'en-GB': 'Roles',
        },
    },
    adminNavigationSideBarTenants: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Mandanten',
            'en-GB': 'Tenants',
        },
    },
    adminNavigationSideBarUsers: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Benutzer',
            'en-GB': 'Users',
        },
    },
    adminNavigationSideBarUserfields: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu'],
        translations: {
            'de-DE': 'Benutzerfelder',
            'en-GB': 'User fields',
        },
    },
    adminNavigationSideBarCategoryShop: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu', 'category'],
        translations: {
            'de-DE': 'Shop',
            'en-GB': 'Store',
        },
    },
    adminNavigationSideBarCategoryUserAdministration: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu', 'category'],
        translations: {
            'de-DE': 'Benutzerverwaltung',
            'en-GB': 'User administration',
        },
    },
    adminNavigationSideBarCategorySettings: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminNavigation,
        tags: ['static', 'admin', 'navigation', 'menu', 'category'],
        translations: {
            'de-DE': 'Einstellungen',
            'en-GB': 'Settings',
        },
    },

    // adminNavigation: {
    //     context: undefined,
    //     locked: true,
    //     group: ETranslationGroups.adminNavigation,
    //     tags: ['static', 'admin', 'navigation', 'menu'],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
