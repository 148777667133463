import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const toastTranslations: ITranslationMap = {
    toastErrorCopyingArticle: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cart'],
        translations: {
            'de-DE': 'Fehler beim kopieren des Artikels.',
            'en-GB': 'Error copying the article.',
        },
    },

    toastErrorUpdatingCart: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cart'],
        translations: {
            'de-DE': 'Fehler beim Aktualisieren des Warenkorbs.',
            'en-GB': 'Error updating the shopping cart.',
        },
    },
    toastErrorLoadingPreview: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error'],
        translations: {
            'de-DE': 'Fehler beim Laden der Vorschau',
            'en-GB': 'Error loading the preview',
        },
    },

    toastErrorWhenDeletingImage: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cms'],
        translations: {
            'de-DE': 'Fehler beim Löschen des Bildes.',
            'en-GB': 'Error when deleting the image.',
        },
    },

    toastErrorWhenUploadingImage: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cms'],
        translations: {
            'de-DE': 'Fehler beim Hochladen des Bildes.',
            'en-GB': 'Error when uploading the image.',
        },
    },

    toastErrorURLNotValid: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cms'],
        translations: {
            'de-DE': 'URL ist nicht valide und muss mit https:// beginnen',
            'en-GB': 'URL is not valid and must begin with https://',
        },
    },

    toastErrorFillAllFields: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'checkout'],
        translations: {
            'de-DE': 'Bitte füllen Sie alle Pflichtfelder aus.',
            'en-GB': 'Please fill in all mandatory fields.',
        },
    },

    toastErrorWhenSendingOrder: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'checkout'],
        translations: {
            'de-DE': 'Fehler beim Absenden der Bestellung',
            'en-GB': 'Error when sending the order',
        },
    },

    toastErrorUploadingThemeImage: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'theme'],
        translations: {
            'de-DE': 'Fehler beim Hochladen des Bildes',
            'en-GB': 'Error uploading the image',
        },
    },

    toastErrorWhenChangingDescription: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cart', 'product'],
        translations: {
            'de-DE': 'Fehler beim ändern der Beschreibung',
            'en-GB': 'Error when changing the description',
        },
    },

    toastErrorWhenSavingProfile: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'profile'],
        translations: {
            'de-DE': 'Fehler beim speichern des Profils',
            'en-GB': 'Error when saving the profile',
        },
    },

    toastErrorAddingImageTryAgain: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'image'],
        translations: {
            'de-DE': 'Fehler beim Hinzufügen des Bildes. Bitte versuchen Sie es erneut.',
            'en-GB': 'Error adding the image. Please try again.',
        },
    },
    toastErrorRemovingImageTryAgain: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'image'],
        translations: {
            'de-DE': 'Fehler beim Entfernen des Bildes. Bitte versuchen Sie es erneut.',
            'en-GB': 'Error when removing the image. Please try again.',
        },
    },
    toastErrorWhenUpdatingThumbnails: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'thumbnail'],
        translations: {
            'de-DE': 'Fehler beim Aktualisieren der Thumbnails',
            'en-GB': 'Error when updating the thumbnails',
        },
    },
    toastErrorPasswordCouldNotBeChanged: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'password'],
        translations: {
            'de-DE': 'Passwort konnte nicht geändert werden.',
            'en-GB': 'Password could not be changed.',
        },
    },
    toastErrorWhenSavingContent: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cms'],
        translations: {
            'de-DE': 'Fehler beim speichern des Inhalts',
            'en-GB': 'Error when saving the content',
        },
    },
    toastErrorWhenAddingLayout: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cms'],
        translations: {
            'de-DE': 'Fehler beim Hinzufügen des Layouts',
            'en-GB': 'Error when adding the layout',
        },
    },
    toastErrorWhenRemovingLayout: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cms'],
        translations: {
            'de-DE': 'Fehler beim Entfernen des Layouts',
            'en-GB': 'Error when removing the layout',
        },
    },
    toastErrorLoginNotPossibleWithThisData: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'login'],
        translations: {
            'de-DE': 'Login mit diesen Daten nicht möglich',
            'en-GB': 'Login not possible with this data',
        },
    },
    toastErrorLoginError: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'login'],
        translations: {
            'de-DE': 'Login Fehler',
            'en-GB': 'Login error',
        },
    },
    toastErrorWhenChangingLayoutType: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cms'],
        translations: {
            'de-DE': 'Fehler beim Ändern des Layout-Typs',
            'en-GB': 'Error when changing the layout type',
        },
    },

    toastErrorWhenSavingLayout: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'cms'],
        translations: {
            'de-DE': 'Fehler beim Speichern des Layouts',
            'en-GB': 'Error when saving the layout',
        },
    },

    toastErrorLanguageNotUpdated: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'language'],
        translations: {
            'de-DE': 'Sprache konnte nicht aktualisiert werden',
            'en-GB': 'Language could not be updated',
        },
    },

    toastErrorWhenDeletingRole: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Fehler beim Löschen der Rolle',
            'en-GB': 'Error when deleting the role',
        },
    },

    toastErrorEnterRoleName: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Bitte Rollennamen angeben',
            'en-GB': 'Please enter role name',
        },
    },
    toastErrorNameError: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Name darf nur aus Kleinbuchstaben und Zahlen bestehen',
            'en-GB': 'Name may only consist of lowercase letters and numbers',
        },
    },
    toastErrorWhenCreatingRole: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Fehler beim Erstellen eines neuen Rolle.',
            'en-GB': 'Error when creating a new role.',
        },
    },
    toastErrorWhenSavingProductUpdates: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Speichern von Updates für das Produkt.',
            'en-GB': 'Error when saving updates for the product.',
        },
    },
    toastErrorWhenSavingProductNameTranslationsUpdates: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Speichern von Produktnamenübersetzungen.',
            'en-GB': 'Error when saving product name translations.',
        },
    },
    toastErrorWhenArchivingProduct: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Archivieren des Produkts',
            'en-GB': 'Error when archiving the product',
        },
    },
    toastErrorWhenUnArchivingProduct: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim wiederherstellen des Produkts',
            'en-GB': 'Error when unarchiving the product',
        },
    },
    toastErrorWhenDeleteProduct: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Löschen des Produkts',
            'en-GB': 'Error when deleting the product',
        },
    },

    toastErrorCouldNotCopyLink: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Link konnte nicht in die Zwischenablage kopiert werden!',
            'en-GB': 'Link could not be copied to the clipboard!',
        },
    },
    toastErrorWhenCreatingNewProduct: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Erstellen eines neuen Produkts.',
            'en-GB': 'Error when creating a new product.',
        },
    },
    toastErrorWhenArchivingPricelist: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Archivieren der Preisliste',
            'en-GB': 'Error when archiving the price list',
        },
    },
    toastErrorWhenUnArchivingPricelist: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Error when restoring the price list',
            'en-GB': 'Fehler beim wiederherstellen der Preisliste',
        },
    },
    toastErrorPleaseEnterPricelist: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Bitte Preisliste angeben',
            'en-GB': 'Please enter price list',
        },
    },
    toastErrorWhenCreatingPricelist: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Erstellen eines neuen Perisliste.',
            'en-GB': 'Error when creating a new peris list.',
        },
    },
    toastErrorWhenCreatingCategory: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Erstellen einer neuen Kategorie.',
            'en-GB': 'Error when creating a new category.',
        },
    },
    toastErrorEnterUsername: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Bitte Benutzernamen angeben',
            'en-GB': 'Please enter your username',
        },
    },

    toastErrorUsernameError: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Benutzername darf nur aus Kleinbuchstaben und Zahlen bestehen.',
            'en-GB': 'User name may only consist of lower case letters and numbers.',
        },
    },
    toastErrorWhenCreatingTenant: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Fehler beim Erstellen eines neuen Mandanten.',
            'en-GB': 'Error when creating a new client.',
        },
    },
    toastErrorWhenCreatingNewField: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Fehler beim Erstellen eines neuen Feldes.',
            'en-GB': 'Error when creating a new field.',
        },
    },
    toastErrorFieldNameError: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Feldname darf nur aus Buchstaben und Zahlen bestehen.',
            'en-GB': 'Field name may only consist of letters and numbers.',
        },
    },
    toastErrorEnterFieldname: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'product'],
        translations: {
            'de-DE': 'Bitte Feldname angeben!',
            'en-GB': 'Please enter field name!',
        },
    },
    toastErrorWhenSavingLayoutOrder: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'cms'],
        translations: {
            'de-DE': 'Fehler beim Speichern der Reihenfolge',
            'en-GB': 'Error saving the layout order',
        },
    },
    toastErrorWhenCreatingPage: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'cms'],
        translations: {
            'de-DE': 'Fehler beim Erstellen der Seite',
            'en-GB': 'Error when creating the page',
        },
    },
    toastErrorWhenCreatingUser: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Fehler beim Erstellen eines neuen Benutzer.',
            'en-GB': 'Error when creating a new user.',
        },
    },
    toastErrorEmailWrongSyntax: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'E-Mail entspricht keinem gültigen Syntax',
            'en-GB': 'E-mail does not correspond to a valid syntax',
        },
    },
    toastErrorPleaseEnterEmail: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Bitte E-Mail angeben',
            'en-GB': 'Please enter your e-mail address',
        },
    },
    toastErrorWhenSavingField: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin', 'user'],
        translations: {
            'de-DE': 'Fehler beim Speichern des Feldes',
            'en-GB': 'Error when saving the field',
        },
    },
    toastErrorFieldNotDeleted: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'admin'],
        translations: {
            'de-DE': 'Feld konnte nicht gelöscht werden',
            'en-GB': 'Field could not be deleted',
        },
    },
    toastErrorWhenImportingPricelist: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'product'],
        translations: {
            'de-DE':
                'Fehler beim Importieren der Preisliste. Bitte versuchen Sie es später erneut.',
            'en-GB': 'Error importing the price list. Please try again later.',
        },
    },

    toastErrorWhenRequestingNewPassword: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'user'],
        translations: {
            'de-DE': 'Bei der Anforderung eines neuen Passworts ist etwas schief gelaufen',
            'en-GB': 'Something went wrong while requesting new password',
        },
    },

    toastErrorWhenTransmittingCode: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'user', 'login'],
        translations: {
            'de-DE': 'Fehler bei der Übermittlung des Codes.',
            'en-GB': 'Error during transmission of the code.',
        },
    },

    toastErrorWhenExportingPricelist: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'product'],
        translations: {
            'de-DE':
                'Fehler beim Exportieren der Preisliste. Bitte versuchen Sie es später erneut.',
            'en-GB': 'Error exporting the price list. Please try again later.',
        },
    },

    toastErrorWhenSavingTranslation: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'error', 'translation'],
        translations: {
            'de-DE': 'Fehler beim Speichern',
            'en-GB': 'Error when saving',
        },
    },

    // toastError: {
    //     context: undefined,
    //     group: ETranslationGroups.toast,
    //     tags: ['static', 'toast', 'error', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },

    // SUCCESS #####################################################################################

    toastSuccessFieldHasBeenDeleted: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'user'],
        translations: {
            'de-DE': 'Feld wurde gelöscht',
            'en-GB': 'Field has been deleted',
        },
    },
    toastSuccessRoleSaved: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'user'],
        translations: {
            'de-DE': 'Rolle erfolgreich gespeichert',
            'en-GB': 'Role successfully saved',
        },
    },
    toastSuccessEmailChanged: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'user'],
        translations: {
            'de-DE': 'E-Mail erfolgreich geändert.',
            'en-GB': 'E-mail successfully changed.',
        },
    },
    toastSuccessUsernameChanged: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'user'],
        translations: {
            'de-DE': 'Benutzername erfolgreich geändert.',
            'en-GB': 'Username successfully changed.',
        },
    },
    toastSuccessNewFieldUpdated: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'user'],
        translations: {
            'de-DE': 'Neues Feld erfolgreich aktualisiert.',
            'en-GB': 'New field successfully updated.',
        },
    },
    toastSuccessRoleDeleted: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'user'],
        translations: {
            'de-DE': 'Rolle erfolgreich gelöscht.',
            'en-GB': 'Role successfully deleted.',
        },
    },
    toastSuccessProfileSaved: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'user'],
        translations: {
            'de-DE': 'Profil erfolgreich gespeichert',
            'en-GB': 'Profile successfully saved',
        },
    },
    toastSuccessProductArchived: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Produkt erfolgreich archiviert',
            'en-GB': 'Product successfully archived',
        },
    },
    toastSuccessProductUnArchived: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Produkt erfolgreich wiederhergestellt',
            'en-GB': 'Product successfully unarchived',
        },
    },
    toastSuccessProductDelete: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Product successfully deleted',
            'en-GB': '',
        },
    },
    toastSuccessProductSaved: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Produkte erfolgreich gespeichert',
            'en-GB': 'Product Successfully saved',
        },
    },
    toastSuccessProductNameTranslationsSaved: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Die Übersetzungen der Produktnamen wurden erfolgreich gespeichert.',
            'en-GB': 'Product name translations successfully saved.',
        },
    },
    toastSuccessPricelistUnarchived: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Preisliste erfolgreich wiederhergestellt',
            'en-GB': 'Price list successfully unarchived',
        },
    },
    toastSuccessPricelistArchived: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Preisliste erfolgreich archiviert',
            'en-GB': 'Price list successfully archived',
        },
    },
    toastSuccessLinkCopiedToClipboard: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Link in die Zwischenablage kopiert.',
            'en-GB': 'Link copied to the clipboard.',
        },
    },
    toastSuccessCartItemDescriptionChanged: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'cart', 'item'],
        translations: {
            'de-DE': 'Beschreibung erfolgreich geändert',
            'en-GB': 'Description successfully changed',
        },
    },
    toastSuccessMediaDeleted: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin'],
        translations: {
            'de-DE': 'Medien erfolgreich gelöscht',
            'en-GB': 'Media successfully deleted',
        },
    },
    toastSuccessLinkCopied: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE': 'Link in die Zwischenablage kopiert.',
            'en-GB': 'Link copied to the clipboard.',
        },
    },
    toastSuccessPriceListExported: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'product'],
        translations: {
            'de-DE': 'Die Preisliste wird exportiert.',
            'en-GB': 'The price list is exported.',
        },
    },
    toastSuccessPricelistExported: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'product'],
        translations: {
            'de-DE': 'Die Preisliste wird exportiert.',
            'en-GB': 'The price list is exported.',
        },
    },
    toastSuccessOrderSent: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'checkout'],
        translations: {
            'de-DE': 'Bestellung erfolgreich abgesendet',
            'en-GB': 'Order successfully sent',
        },
    },
    toastSuccessPricelistImported: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'product'],
        translations: {
            'de-DE': 'Die Preisliste wurde importiert.',
            'en-GB': 'The price list has been imported.',
        },
    },
    toastSuccessPagePublished: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'cms'],
        translations: {
            'de-DE': 'Seite wurde veröffentlicht!',
            'en-GB': 'Page has been published!',
        },
    },
    toastSuccessSavedContents: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'cms'],
        translations: {
            'de-DE': 'Inhalt gespeichert',
            'en-GB': 'Contents saved',
        },
    },
    toastSuccessDescriptionChanged: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'product'],
        translations: {
            'de-DE': 'Beschreibung erfolgreich geändert',
            'en-GB': 'Description successfully changed',
        },
    },
    toastSuccessImageUploaded: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success'],
        translations: {
            'de-DE': 'Bild erfolgreich hochgelade',
            'en-GB': 'Image successfully uploaded',
        },
    },
    toastSuccessThemeSettingsSavedPleaseReload: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'theme'],
        translations: {
            'de-DE': 'Theme-Einstellungen gespeichert. Bitte laden Sie die Seite neu',
            'en-GB': 'Theme settings saved. Please reload the page',
        },
    },
    toastSuccessLayoutTypeChanged: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'cms'],
        translations: {
            'de-DE': 'Layout-Typ erfolgreich geändert',
            'en-GB': 'Layout type successfully changed',
        },
    },
    toastSuccessLocalizationsSaved: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', ''],
        translations: {
            'de-DE': 'Lokalisationen gespeichert',
            'en-GB': 'Localizations saved',
        },
    },
    // toastSuccessLanguageUpdated: {
    //     context: undefined,
    //     group: ETranslationGroups.toast,
    //     tags: ['static', 'toast', 'success', 'user'],
    //     translations: {
    //         'de-DE': 'Sprache erfolgreich aktualisiert.',
    //         'en-GB': 'Language successfully updated.',
    //     },
    // },
    toastSuccessProductThumbnailsActualizedPleaseUpdateProductPage: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'admin', 'product'],
        translations: {
            'de-DE':
                'Produkt mit neuen Thumbnails aktualisiert. Bitte aktualiseren Sie die Produktseite im administrativen Bereich.',
            'en-GB':
                'Product updated with new thumbnails. Please update the product page in the administrative area.',
        },
    },
    toastSuccessProductSavedInCart: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'cart', 'product'],
        translations: {
            'de-DE': 'Das Produkt wurde erfolgreich in der Merkliste im Warenkorb gespeichert.',
            'en-GB': 'The product has been successfully saved in the shopping cart.',
        },
    },
    toastSuccessPasswordChangedLogInWithNewPassword: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'success', 'user'],
        translations: {
            'de-DE':
                'Passwort erfolgreich geändert. Bitte loggen sie sich mit dem neuen Passwort ein.',
            'en-GB': 'Password changed successfully. Please log in with the new password.',
        },
    },

    // INFO ################################################

    toastInfoProofInVKPossibleWithoutVault: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'info', 'cart', 'item'],
        translations: {
            'de-DE': 'Proof im VK noch ohne Vault möglich machen',
            'en-GB': 'Make proof in VK possible without Vault',
        },
    },
    toastInfoWillReceiveEmailWithInstructions: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'info', 'user', 'login'],
        translations: {
            'de-DE':
                'Wenn Ihre E-Mail-Adresse in unserem System vorhanden ist, erhalten Sie in Kürze eine E-Mail mit Anweisungen.',
            'en-GB':
                'If your e-mail address is available in our system, you will shortly receive an e-mail with instructions.',
        },
    },
    toastInfoBetaFeature: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'info'],
        translations: {
            'de-DE': 'Dies ist eine Beta-Funktion',
            'en-GB': 'This is a beta feature',
        },
    },
    // toastInfo: {
    //     context: undefined,
    //     group: ETranslationGroups.toast,
    //     tags: ['static', 'toast', 'info', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },

    // WARNING ############################################

    toastWarnNotAllProductsInStockOrAvailable: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'warn', ''],
        translations: {
            'de-DE': 'Nicht alle Produkte sind auf Lager oder bestellbar!',
            'en-GB': 'Not all products are in stock or available to order!',
        },
    },
    toastWarnPageDeactivated: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'warn', 'cms'],
        translations: {
            'de-DE': 'Seite wurde inaktive gestellt!',
            'en-GB': 'Page has been deactivated!',
        },
    },
    toastWarnSthWentWrong: {
        context: undefined,
        group: ETranslationGroups.toast,
        tags: ['static', 'toast', 'warn', 'user'],
        translations: {
            'de-DE': 'Etwas ist schief gelaufen',
            'en-GB': 'Something has gone wrong',
        },
    },
    // toastWarn: {
    //     context: undefined,
    //     group: ETranslationGroups.toast,
    //     tags: ['static', 'toast', 'warn', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
