import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminUserfieldsTranslations: ITranslationMap = {
    adminUserfieldsHeaderUserFields: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'header', 'headline'],
        translations: {
            'de-DE': 'Benutzer Felder',
            'en-GB': 'User fields',
        },
    },
    adminUserfieldsDescriptionUserFields: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'description'],
        translations: {
            'de-DE': 'Hier können Sie die Benutzerfelder verwalten.',
            'en-GB': 'You can manage the user fields here.',
        },
    },
    adminUserfieldsTableFieldName: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'table'],
        translations: {
            'de-DE': 'Feldname',
            'en-GB': 'Field name',
        },
    },
    adminUserfieldsTableLabel: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'table'],
        translations: {
            'de-DE': 'Label',
            'en-GB': 'Label',
        },
    },
    adminUserfieldsTableRequired: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'table'],
        translations: {
            'de-DE': 'Pflichtfeld',
            'en-GB': 'Required',
        },
    },
    adminUserfieldsTableUse: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'table'],
        translations: {
            'de-DE': 'Anwenden',
            'en-GB': 'Use',
        },
    },
    adminUserfieldsTableType: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'table'],
        translations: {
            'de-DE': 'Typ',
            'en-GB': 'Type',
        },
    },
    adminUserfieldsButtonCreate: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'button'],
        translations: {
            'de-DE': 'Anlegen',
            'en-GB': 'Create',
        },
    },
    adminUserfieldsPlaceholderNewFieldName: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminUserfields,
        tags: ['static', 'admin', 'userfields', 'placeholder'],
        translations: {
            'de-DE': 'Neuer Feldname',
            'en-GB': 'New field name',
        },
    },
    // adminUserfields: {
    //     context: undefined,
    //     locked: true,
    //     group: ETranslationGroups.adminUserfields,
    //     tags: ['static', 'admin', 'userfields', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
