import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const pageCMSTranslation: ITranslationMap = {
    cmsPageUrlInvalid: {
        context: 'Content Management Warning for URL',
        group: ETranslationGroups.cmsPage,
        tags: ['static', 'warning', 'cms'],
        translations: {
            'en-GB':
                'URL is not valid and must start with https:// or be relative and start with /',
            'de-DE':
                'URL ist nicht valide und muss mit https:// beginnen oder relativ sein und mit / anfangen',
        },
    },
    cmsPageUrlInfo: {
        context: '',
        group: ETranslationGroups.cmsPage,
        tags: ['static', 'info', 'cms'],
        translations: {
            'de-DE': 'Url muss mit https:// beginnen oder relativ sein und mit / anfangen.',
            'en-GB': 'URL must start with https:// or be relative and start with /.',
        },
    },
    cmsPageNotFoundTitle: {
        context: 'Content Management Warning for URL',
        group: ETranslationGroups.cmsPage,
        tags: ['static', 'warning', 'cms'],
        translations: {
            'en-GB': 'Page not found',
            'de-DE': 'Seite nicht gefunden',
        },
    },
    cmsPageNotFoundDescription: {
        context: 'Content Management Warning for URL',
        group: ETranslationGroups.cmsPage,
        tags: ['static', 'warning', 'cms'],
        translations: {
            'en-GB': 'The page was not found. Please make sure the URL is correct.',
            'de-DE':
                'Leider wurde die Seite nicht gefunden. Bitte vergewissern Sie sich, dass die URL korrekt ist',
        },
    },
    cmsPageNotFoundButtonLabel: {
        context: 'Content Management Warning for URL',
        group: ETranslationGroups.cmsPage,
        tags: ['static', 'warning', 'cms'],
        translations: {
            'en-GB': 'Go to page management',
            'de-DE': 'Zur Seitenverwaltung',
        },
    },
};
