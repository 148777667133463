import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const adminTableTranslation: ITranslationMap = {
    adminTablePage: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminTable,
        tags: ['static', 'admin', 'table'],
        translations: {
            'de-DE': 'Seite',
            'en-GB': 'Page',
        },
    },
    adminTableResultsPerPage: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminTable,
        tags: ['static', 'admin', 'table'],
        translations: {
            'de-DE': 'Ergebnisse pro Seite',
            'en-GB': 'Results per page',
        },
    },
    adminTableOf: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminTable,
        tags: ['static', 'admin', 'table'],
        translations: {
            'de-DE': 'von',
            'en-GB': 'of',
        },
    },
    adminTableShowing: {
        context: undefined,
        locked: true,
        group: ETranslationGroups.adminTable,
        tags: ['static', 'admin', 'table'],
        translations: {
            'de-DE': 'Anzeige',
            'en-GB': 'Showing',
        },
    },

    // adminTable: {
    //     context: undefined,
    //     locked: true,
    //     group: ETranslationGroups.adminTable,
    //     tags: ['static', 'admin', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
