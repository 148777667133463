import { ITranslationMap } from './translationMapStatic';
import { ETranslationGroups } from './translation.enums';

export const confirmTranslations: ITranslationMap = {
    confirmDeleteTheField: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'user'],
        translations: {
            'de-DE': 'Wollen Sie das Feld wirklich löschen?',
            'en-GB': 'Do you really want to delete the field?',
        },
    },
    confirmSendEmailToResetPassword: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'user'],
        translations: {
            'de-DE': 'Möchten Sie wirklich eine E-Mail senden für das Neu-Setzen des Passworts?',
            'en-GB': 'Do you really want to send an e-mail to reset the password?',
        },
    },
    confirmAreYouSure: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm'],
        translations: {
            'de-DE': 'Sind Sie sicher?',
            'en-GB': 'Are you sure?',
        },
    },
    confirmDeleteImage: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'cms'],
        translations: {
            'de-DE': 'Bild wirklich löschen?',
            'en-GB': 'Really delete the image?',
        },
    },
    confirmTemplateToOverwriteCurrentValues: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'theme'],
        translations: {
            'de-DE': 'Soll die Vorlage die aktuellen Werte überschreiben?',
            'en-GB': 'Should the template overwrite the current values?',
        },
    },
    confirmDeleteRole: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'user'],
        translations: {
            'de-DE': 'Möchten Sie die Rolle wirklich löschen?',
            'en-GB': 'Do you really want to delete the role?',
        },
    },
    confirmFinishArticle: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'cart', 'item'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie den Artikel abschließen möchten?',
            'en-GB': 'Are you sure you want to finish the article?',
        },
    },
    confirmArchiveProduct: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'product'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie dieses Produkt archivieren möchten?',
            'en-GB': 'Are you sure you want to archive this product?',
        },
    },
    confirmDeleteCategory: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'product'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie diese Kategorie löschen möchten?',
            'en-GB': 'Are you sure you want to delete this category?',
        },
    },
    confirmCurrentTemplateOverwrite: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'user'],
        translations: {
            'de-DE': 'Sollen die aktuellen Templates überschrieben werden?',
            'en-GB': 'Should the current templates be overwritten?',
        },
    },
    confirmArchivePricelist: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'product'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie dieses Preisliste archivieren möchten?',
            'en-GB': 'Are you sure you want to archive this price list?',
        },
    },
    confirmUnArchivePricelist: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'product'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie dieses Preisliste wiederherstellen möchten?',
            'en-GB': 'Are you sure you want to unarchive this price list?',
        },
    },
    confirmDeleteField: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie das Feld löschen möchten?',
            'en-GB': 'Are you sure you want to delete the field?',
        },
    },
    confirmDeleteLayout: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'cms'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie das Layout löschen möchten?',
            'en-GB': 'Are you sure you want to delete the layout?',
        },
    },
    confirmConfirm: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'theme'],
        translations: {
            'de-DE': 'Bestätigen',
            'en-GB': 'Confirm',
        },
    },
    confirmDeleteLocalization: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'user', 'localization'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie diese Lokalisierung löschen möchten?',
            'en-GB': 'Are you sure you want to delete this localization?',
        },
    },
    confirmDeleteStock: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'product'],
        translations: {
            'de-DE':
                'Soll der Bestand wirklich gelöscht werden? Dieser muss manuell wiederhergestellt werden.',
            'en-GB': 'Should the stock really be deleted? This must be restored manually.',
        },
    },
    confirmOverwriteCurrentThumbnailsWithVisiblePreviews: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', ''],
        translations: {
            'de-DE':
                'Diese Aktion überschreibt die aktuellen Thumbnails mit den sichtbaren Previews. Sind Sie sicher fortzufahren?',
            'en-GB':
                'This action overwrites the current thumbnails with the visible previews. Are you sure you want to continue?',
        },
    },
    confirmRemoveItem: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'admin', 'item'],
        translations: {
            'de-DE': "'Sind Sie sicher, dass das Feld nicht mehr angezeigt werden soll?'",
            'en-GB': 'Are you sure that the field should no longer be displayed?',
        },
    },
    confirmSetDefaultLocalization: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'user'],
        translations: {
            'de-DE':
                'Sind Sie sicher, dass Sie diese Lokalisierung als Standard einstellen können?',
            'en-GB': 'Are you sure you can set this localization as the default?',
        },
    },

    confirmDeleteProduct: {
        context: undefined,
        group: ETranslationGroups.confirm,
        tags: ['static', 'confirm', 'product', 'admin'],
        translations: {
            'de-DE': 'Sind Sie sicher, dass Sie dieses Produkt löschen möchten?',
            'en-GB': 'Are you sure you want to delete this product?',
        },
    },

    // confirm: {
    //     context: undefined,
    //     group: ETranslationGroups.confirm,
    //     tags: ['static', 'confirm', ''],
    //     translations: {
    //         'de-DE': '',
    //         'en-GB': '',
    //     },
    // },
};
